import React from 'react';
import { Heading, TypographyVisualStyle } from 'react-magma-dom';
import styled from '@emotion/styled';

const StyledHeading = styled(Heading)`
  text-align: center;
  padding: 20px 0px 15px;
`;

export interface FormHeaderInfoProps {}

export const FormHeaderInfo: React.FunctionComponent<FormHeaderInfoProps> = (props) => {
  return (
    <StyledHeading level={1} visualStyle={TypographyVisualStyle.headingSmall} noMargins>
      {props.children}
    </StyledHeading>
  );
};
