import React from 'react';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { FacultyDataKeys } from '../../screens/Profile/FacultyDataKeys';
import { isInstitutionStateValid, ValidatorResponse } from './Validator';

export const TEST_ID = {
  INPUT_INSTITUTION_STATE: '447BE88C-CC36-4D37-A96E-37746573C7D8'
};
export const minLength = 2;
export const maxLength = 50;

export interface InstitutionStateInputProps extends ValidatedDataInputProps {}

export const InstitutionStateInput: React.FC<InstitutionStateInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isInstitutionStateValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      {...props}
    />
  );
};

InstitutionStateInput.defaultProps = {
  dataLabel: 'Institution State/Province',
  name: FacultyDataKeys.entityState,
  testId: TEST_ID.INPUT_INSTITUTION_STATE,
  required: true,
  helperMessage: `${maxLength} characters allowed`,
  whenValidated: () => {return;}
};
