import { generateMarker } from './dom-build';

export const expandNeedHelp = () => {
  const widgetFooter = document.querySelector('.auth-footer');
  const linksList = widgetFooter?.children[1];
  if (linksList) {
    (linksList as any).style.display = 'block';
  }
};

export const setButtonsToUpper = (contextController: string) => {
  if (contextController === 'idp-discovery') {
    setToUpper('#idp-discovery-submit');
  } else {
    setToUpper('#okta-signin-submit');
  }
};

export const setToUpper = (selector: string) => {
  const selection: HTMLInputElement | null = document.querySelector(selector);
  if (selection) {
    selection.value = selection.value.toUpperCase();
  }
};

export const setToAutocomplete = (target: Element | null) => {
  target?.setAttribute('autocomplete', 'on');
};

/**
 * Inserts a marker with the given test id into the body.
 * Any calls to insert this marker should be made after
 * completion of expected scripts.
 *
 * @param testId Test id for querying
 */
export const insertMarkerIfMissing = (testId: string) => {
  if (
    !document.querySelector(`span[data-type="marker"][data-testid="${testId}"]`)
  ) {
    const marker = generateMarker(testId);
    document.body.appendChild(marker);
  }
};

export const hideErrorElement = (element: Element) => {
  element.classList.add('hidden-error');
};

export const showErrorElement = (element: Element) => {
  element.classList.remove('hidden-error');
};
