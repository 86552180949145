/**
 * UpComm is meant to be a means to accomplish upwards communication
 * from child nodes to some parent node. It also provides context
 * that can be shared amongst each node that has knowledge of it.
 */
import React, {
  useMemo,
  Reducer,
  useState,
  useCallback,
  useContext
} from 'react';
import { useMounted } from '../hooks/useMounted';

export interface ActionFnParams<T> extends UpCommMsg {
  ctx: T;
}
export type ActionFn<T> = (cbParams: ActionFnParams<T>) => T | Promise<T>;

export interface ActionLookup<T> {
  [actionType: string]: ActionFn<T>;
}

export type UpCommMsgChannel = (value: UpCommMsg) => void;

export interface UpCommMsg {
  type: string;
  data?: any;
}

export interface UpCommMethodParams<T> {
  ctx: T;
  sendMsg: UpCommMsgChannel;
}

export interface UpCommProps<T> {
  actionLookup: ActionLookup<T>;
  children:
    | React.ReactNode
    | (({ ctx, sendMsg }: UpCommMethodParams<T>) => React.ReactNode);
  initCtx?: any;
}

const buildReducer = <T extends any>(lookup: ActionLookup<T>) => {
  return (ctx: any, action: UpCommMsg) => {
    const cb = lookup[action.type];
    if (cb) {
      const result = cb({ ctx, ...action });
      if (result) {
        return result;
      }
    }
    return ctx;
  };
};

/**
 * Allows for async actions within our dispatch.
 *
 * @param reducer The reducer function that should handle actions that are called
 * @param initCtx The initial context (if any)
 */
const useAsyncReducer = <T extends any, R extends Reducer<any, any>>(
  reducer: R,
  initCtx?: T
) => {
  const [state, setState] = useState(initCtx);
  const [uncaughtErr, setUncaughtErr] = useState(null);

  const mounted = useMounted();

  const dispatch = useCallback(
    (action: any) => {
      let onError: undefined | ((err: Error) => void) = undefined;
      if (action && action.data) {
        onError = action.data.onError;
      }

      // Wrap in an async fn and immediately call
      (async () => reducer(state, action))()
        .then(newState => {
          // This check needs to be done to ensure that we don't loop
          // forever. If a loop occurs here, React will let it loop
          // without interrupting.
          if (!Object.is(state, newState)) {
            mounted.ifMounted(() => setState(newState));
          }
        })
        .catch(err => {
          // If the data provides an error handler, use it
          if (onError) {
            onError(err);
          } else {
            mounted.ifMounted(() => setUncaughtErr(err));
          }
        });
    },
    [mounted, reducer, state]
  );

  if (uncaughtErr) {
    // If there was an uncaught error, we need to throw it
    try {
      throw uncaughtErr;
    } finally {
      setUncaughtErr(null);
    }
  }

  return [state, dispatch];
};

const UpCommContext = React.createContext(null as any);

/**
 * This is a helper function that makes it easy to setup UpComm hooks
 * with type safety. The initCtx that is passed in will be used for type
 * safety only. The actual initCtx will be passed in via the UpComm
 * component itself.
 *
 * This should not be used directly by components, but rather used to make
 * other hooks.
 *
 * @param _ Initial context used for typing only
 */
// It is unused, but is used for type inference
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useUpComm = <T extends any>(_: T) => {
  const upComm = useContext<UpCommMethodParams<T>>(UpCommContext);
  if (!upComm) {
    throw new Error(
      'Could not find the UpComm Context, be sure this is a child of UpComm'
    );
  }
  return upComm;
};

/**
 * UpComm is meant to handle the upward and downward communication within the
 * component stack. It is similar to an EventEmitter in that it uses messages
 * with a type in order to do certain actions.
 */
export const UpComm = <T extends any>(props: UpCommProps<T>) => {
  const { children, actionLookup, initCtx } = props;

  const reducerFn = useMemo(() => buildReducer(actionLookup), [actionLookup]);
  const [ctx, sendMsg] = useAsyncReducer(reducerFn, initCtx);

  const upCommPayload = useMemo(() => {
    return {
      ctx,
      sendMsg
    };
  }, [ctx, sendMsg]);

  return (
    <React.Fragment>
      <UpCommContext.Provider value={upCommPayload}>
        {'function' === typeof children && children(upCommPayload)}
        {'function' !== typeof children && children}
      </UpCommContext.Provider>
    </React.Fragment>
  );
};

UpComm.defaultProps = {
  initCtx: {}
};
