import React, { useState } from 'react';
import { Modal, Heading, ButtonSize, ButtonColor } from 'react-magma-dom';
import { unlinkAccount } from '../../../../api';
import { Button } from '../../../magma-wrap/Button';
import { UnlinkFnParams, UnlinkState } from './PromoteModal/shared';

export const TEST_ID = {
  BTN_NO_EMAIL: 'btn-no-email',
  BTN_SUBMIT: 'btn-submit'
};

export interface UnlinkModalProps {
  onClose: () => void;
  open: boolean;
  login: string;
  // Testing only
  __initState?: UnlinkState;
}
type UnlinkFn = React.FC<UnlinkFnParams>;
interface UnlinkContentMap {
  [UnlinkState.init]: UnlinkFn;
  [UnlinkState.rename]: UnlinkFn;
  [UnlinkState.success]: UnlinkFn;
  [UnlinkState.error]: UnlinkFn;
}

const unlinkContentLookup: UnlinkContentMap = {
  // eslint-disable-next-line react/display-name
  [UnlinkState.init]: ({ setUnlinkState, closeModal, login }) => (
    <>
      <p>
        You are unlinking your school account, you will no longer be able to
        login with this account. Your email will remain the same:
      </p>
      <div>
        <span className="snippet">{login}</span>
        <Button
          testId={TEST_ID.BTN_NO_EMAIL}
          value="I no longer have access to this mailbox"
          size={ButtonSize.small}
          color={ButtonColor.secondary}
          onClick={() => setUnlinkState(UnlinkState.rename)}
        />
      </div>
      <p>
        We will send you a password reset email as part of the unlink process.
      </p>
      <Button
        value="Cancel"
        color={ButtonColor.secondary}
        onClick={() => {
          closeModal();
        }}
      />
      <Button
        testId={TEST_ID.BTN_SUBMIT}
        value="Submit"
        onClick={async () => {
          try {
            await unlinkAccount();
            setUnlinkState(UnlinkState.success);
          } catch (e) {
            setUnlinkState(UnlinkState.error);
          }
        }}
      />
    </>
  ),
  [UnlinkState.rename]: ({ setUnlinkState }) => {
    return (
      <>
        <p>
          You can set an alternate email address for your account on the profile
          page. After unlinking, a reset password email will be sent to all
          email addresses on file.
        </p>
        <Button value="OK" onClick={() => setUnlinkState(UnlinkState.init)} />
      </>
    );
  },
  [UnlinkState.success]: () => {
    return (
      <>
        <p>
          Your account has been successfully unlinked. Please log out and follow
          the directions in your email to set your password.
        </p>
      </>
    );
  },
  [UnlinkState.error]: () => {
    return (
      <>
        <p>We were unable to unlink your account.</p>
      </>
    );
  }
};

export const UnlinkModal: React.FC<UnlinkModalProps> = props => {
  const {
    open,
    onClose,
    login,
    __initState: initState = UnlinkState.init
  } = props;
  const [unlinkState, setUnlinkState] = useState(initState);
  return (
    <Modal isOpen={open} onClose={onClose?.bind?.(null)}>
      <Heading level={2}>Account Unlink</Heading>
      {unlinkContentLookup[unlinkState]?.({
        login,
        setUnlinkState,
        closeModal: onClose
      })}
    </Modal>
  );
};
