import React, {useState, useEffect} from 'react';
import {
  Modal,
  Heading,
  Button,
  LoadingIndicator,
  Alert,
  AlertVariant, 
  ButtonGroup
} from 'react-magma-dom';
import { PromoteState, PromoteFnParams } from './shared';
import { PromoteStateVerifying } from './PromoteStateVerifying';
import {
  getVerificationStatus,
  enrollFactor,
  swapLogin
} from '../../../../../api';
import { CenterAll } from '../../../../styled';
import { loginLabel } from '../../../../basic/LoginLabel';
import { trackingClient } from '../../../../../ext/tracking';
import { ProfileUpComm } from '../../ProfileData';

export const TEST_ID = {
  LOADING_INDICATOR: 'loading-indicator',
  VERIFYING: 'verifying',
  VERIFIED: 'verified-disclaimer',
  NEW_LOGIN: 'verified-new-login',
  OLD_LOGIN: 'verified-old-login',
  ERROR: 'promote-error',
  CHANGE: 'change-login'
};

export interface PromoteModalProps extends ProfileUpComm {
  open: any;
  onClose: any;
  primaryLogin: string;
  secondaryLogin: string;
  // Testing only
  __initState?: PromoteState;
}

type PromoteFn = React.FC<PromoteFnParams>;

interface PromoteContentMap {
  [PromoteState.init]: PromoteFn;
  [PromoteState.verifying]: PromoteFn;
  [PromoteState.verified]: PromoteFn;
  [PromoteState.changing]: PromoteFn;
  [PromoteState.changed]: PromoteFn;
  [PromoteState.error]: PromoteFn;
}

const promotionContentLookup: PromoteContentMap = {
  // eslint-disable-next-line react/display-name
  [PromoteState.init]: () => (
    <CenterAll data-testid={TEST_ID.LOADING_INDICATOR}>
      <LoadingIndicator />
    </CenterAll>
  ),

  // eslint-disable-next-line react/display-name
  [PromoteState.verifying]: params => (
    <PromoteStateVerifying {...params} testId={TEST_ID.VERIFYING} />
  ),

  // eslint-disable-next-line react/display-name
  [PromoteState.verified]: ({
    primaryLogin,
    setPromoteState,
    setPromoteError,
    closeModal,
    secondaryLogin
  }) => {
    return (
      <>
        <p data-testid={TEST_ID.VERIFIED}>
          After confirming, you will log in as{' '}
          <span className="snippet" data-testid={TEST_ID.NEW_LOGIN}>
            {secondaryLogin}
          </span>{' '}
          and your alternate email will be set to{' '}
          <span className="snippet" data-testid={TEST_ID.OLD_LOGIN}>
            {primaryLogin}
          </span>
          . Password recovery emails will be sent to both emails if you forget
          your password.
        </p>
        <ButtonGroup>
          <Button
            onClick={() => {
              trackingClient.event.changeUsernameSave.changeUsernameClick();
              swapLogin(secondaryLogin)
                .then(() => {
                  setPromoteState(PromoteState.changed);
                })
                .catch(err => {
                  setPromoteState(PromoteState.error);
                  setPromoteError(err.message);
                });
              setPromoteState(PromoteState.changing);
            }}
            testId={TEST_ID.CHANGE}
          >
            Change my {loginLabel}
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </>
    );
  },

  // eslint-disable-next-line react/display-name
  [PromoteState.changing]: () => {
    return (
      <Heading level={3}>
        <CenterAll>
          <LoadingIndicator
            message1="Changing, please wait..."
            message2="Still changing, please wait..."
            message3="Unfortunately this is taking a while, please try again later."
          />
        </CenterAll>
      </Heading>
    );
  },

  // eslint-disable-next-line react/display-name
  [PromoteState.changed]: ({secondaryLogin}) => (
    <>
      <Heading level={3} style={{textAlign: 'center'}}>
        Changed!
      </Heading>
      <p>
        Your {loginLabel} has been changed. Please use{' '}
        <span className="snippet">{secondaryLogin}</span>{' '}
        to login from now on. Closing this modal will reload the page.
      </p>
    </>
  ),

  // eslint-disable-next-line react/display-name
  [PromoteState.error]: ({promoteError}) => (
    <>
      <Alert testId={TEST_ID.ERROR} variant={AlertVariant.danger}>
        {promoteError
          ? promoteError
          : "Something went wrong on our side, we can't continue."}
      </Alert>
    </>
  )
};

export const PromoteModal: React.FC<PromoteModalProps> = props => {
  const {
    primaryLogin,
    open,
    onClose,
    secondaryLogin,
    __initState: initState = PromoteState.init
  } = props;

  const [promoteState, setPromoteState] = useState(initState);
  const [lastState, setLastState] = useState(promoteState);
  const [promoteError, setPromoteError] = useState('');

  const enrollUser = async () => {
    // Do this if not already verifying
    const enroll = await enrollFactor(secondaryLogin);
    if (enroll.verifying) {
      setPromoteState(PromoteState.verifying);
    } else {
      setPromoteState(PromoteState.error);
      setPromoteError('Failed to enroll, sorry for the inconvenience.');
    }
  };

  function enrollOrSetStatus(secondaryLogin: string, enrollUser: () => Promise<void>, setPromoteState: React.Dispatch<React.SetStateAction<PromoteState>>): ((value: { email: string | undefined; verifying: boolean; verified: boolean; }) => void | PromiseLike<void | undefined> | undefined) | null | undefined {
    return ({verified, verifying, email: emailWithFactor}) => {
      if (secondaryLogin !== emailWithFactor ||
        (!verified && !verifying)) {
        return enrollUser();
      } else if (verifying) {
        setPromoteState(PromoteState.verifying);
      } else if (verified) {
        setPromoteState(PromoteState.verified);
      }
    };
  }

  useEffect(() => {
    if (lastState !== promoteState) {
      setLastState(promoteState);
      if (PromoteState.init !== lastState) {
        trackingClient.event.verifyAlternateEmail.unloadViewPage({
          tags: {step: lastState}
        });
      }
      if (PromoteState.init !== promoteState) {
        trackingClient.event.verifyAlternateEmail.loadViewPage({
          tags: {step: promoteState}
        });
      }
    }
  }, [lastState, promoteState]);

  useEffect(() => {
    if (open) {
      // Only once this is actually opened
      if (promoteState === PromoteState.init) {
        // Get the current status and adjust from there
        getVerificationStatus()
          .then(enrollOrSetStatus(secondaryLogin, enrollUser, setPromoteState))
          .catch(async err => {
            if (
              'external.error' === err.errorCode &&
              'NOT_SETUP' === err.message
            ) {
              await enrollUser();
            } else {
              setPromoteState(PromoteState.error);
              setPromoteError('');
            }
          });
      }
    }
  }, [open, promoteState, secondaryLogin]);
  return (
    <Modal
      // Since this is a multi-step process, it might be
      // annoying for someone to accidentally click out
      isBackgroundClickDisabled={true}
      isEscKeyDownDisabled={true}
      isOpen={open}
      onClose={() => {
        if (
          PromoteState.changed === promoteState ||
          PromoteState.changing === promoteState
        ) {
          // This is temporary until we can better refresh
          // visual data based using UpComm.
          window?.location?.reload();
        }
        setPromoteState(PromoteState.init);
        props?.onClose();
      }}
      header={`Alternate ${loginLabel} as Primary ${loginLabel}`}
    >
      {promotionContentLookup[promoteState]?.({
        primaryLogin,
        setPromoteState,
        setPromoteError,
        closeModal: onClose,
        promoteError,
        secondaryLogin
      })}
    </Modal>
  );
};


