/** @jsx jsx */
import React, { PropsWithChildren } from 'react';
import { jsx } from '@emotion/core';

import { DataBlock } from './DataBlock';
import { Link } from '../../../basic/Links';

export const TEST_ID = {
  DATA_BLOCK_MY_DATA: 'data-block-my-data'
};

const helpText = (
  <React.Fragment>
    <span>
      Data is the information that Cengage collects about you, like your email
      address, in order to send information about our products and services. For
      more information see our{' '}
      <Link href="https://www.cengage.com/privacy/" target="_blank" testId="data-privacy-link">
        privacy policy
      </Link>
      .
    </span>
  </React.Fragment>
);

export interface MyDataAndPrefBlockProps extends PropsWithChildren<any> {}

export const MyDataAndPrefBlock: React.FunctionComponent<MyDataAndPrefBlockProps> = (
  props: MyDataAndPrefBlockProps
) => {
  return (
    <DataBlock
      blockName="My Data & Preferences"
      helpText={helpText}
      testId={TEST_ID.DATA_BLOCK_MY_DATA}
    >
      {props.children}
    </DataBlock>
  );
};
