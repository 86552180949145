import React from 'react';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { isPreferredNameValid, ValidatorResponse } from './Validator';

export const maxLength = 50;
export const TEST_ID = {
  MAIN: 'preferred-name-input'
};

export interface PreferredNameInputProps extends ValidatedDataInputProps {
  persistEvent?: boolean
}

export const PreferredNameInput: React.FC<PreferredNameInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isPreferredNameValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      {...props}
    />
  );
};

PreferredNameInput.defaultProps = {
  dataLabel: 'Preferred Name',
  name: UserDataKeys.preferredName,
  testId: TEST_ID.MAIN,
  id: 'preferred-name-input',
  required: false,
  helperMessage: `max ${maxLength} letters`,
  persistEvent: false,
  whenValidated: () => { return; }
};
