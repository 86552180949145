import React from 'react';
import { BoundaryProps, cleanProps, genPathTo } from './Boundary';
import { FacultyLanding, FacultyLandingProps } from '../steps/FacultyLanding';

export const path = '/landing';

export interface FacultyLandingBoundaryProps
  extends BoundaryProps,
    FacultyLandingProps {}

export const FacultyLandingBoundary: React.FC<FacultyLandingBoundaryProps> = props => {
  props = cleanProps(props);

  return <FacultyLanding {...props} />;
};

export interface SupportedPathOptions {}

export const pathTo = genPathTo<SupportedPathOptions>(path);

FacultyLandingBoundary.defaultProps = {
  path
};
