/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Checkbox } from 'react-magma-dom';
import { ProfileEventTypes, ProfileUpComm } from './ProfileData';
import { UserDataKeys } from './UserDataKeys';

export const TEST_ID = {
  MAIN: 'market-opt-in'
};
export enum MarketOptInValues {
  y = 'Y',
  n = 'N'
}

export interface MarketOptInProps extends ProfileUpComm {
  value?: MarketOptInValues;
  skipDataInit?: boolean;
  onChange?: (v: boolean) => void;
}

export const MarketOptIn: React.FunctionComponent<MarketOptInProps> = (
  props: MarketOptInProps
) => {
  const { value, skipDataInit, onChange } = props;

  useEffect(() => {
    if (!skipDataInit && props.upComm) {
      props.upComm.sendMsg({
        type: ProfileEventTypes.dataInit,
        data: {
          key: UserDataKeys.marketOptIn,
          value: value
        }
      });
    }
  }, []);
  // The dependencies array above is intentionally left empty so that the code in the useEffect 
  // only fires once when the component is loaded on the page
  
  return (
    <Checkbox
      labelText="I want to receive important Cengage updates and information"
      labelStyle={{ fontWeight: 'normal' }}
      defaultChecked={MarketOptInValues.y === value}
      id="market-opt-in"
      testId={TEST_ID.MAIN}
      onChange={v => {
        props.upComm &&
          props.upComm.sendMsg({
            type: ProfileEventTypes.dataChanged,
            data: {
              key: UserDataKeys.marketOptIn,
              value: v.target.checked
                ? MarketOptInValues.y
                : MarketOptInValues.n
            }
          });
        onChange?.(v.target.checked);
      }}
    />
  );
};

MarketOptIn.defaultProps = {
  value: MarketOptInValues.n,
  skipDataInit: true
};
