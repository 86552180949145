/**
 * This file is not covered by testing. It should be treated as very
 * fragile and all changes should be fully understood.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import '@babel/polyfill';
import { HackyGUI, HackyGUIProps, EventType, CallbackMaps } from './HackyGUI';

let Root: React.FC<HackyGUIProps> = HackyGUI;

if (process.env.NODE_ENV === 'development') {
  // Enable hot module reload for dev builds
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { hot } = require('react-hot-loader');
  Root = hot(module)(HackyGUI);
}

const html = document.querySelector('html');
if (html) {
  html.setAttribute('lang', 'en');
}

export const hackyWidgetListener = (widget: any, cbMaps: CallbackMaps) => {
  const generateInserter = () => {
    const result = document.createElement('div');
    // Yeah, this is really fragile, but that's to be expected
    let leadingSibling = document.querySelector(
      '.auth-content-inner > :first-child > :first-child'
    );
    if (!(null === document.querySelector('[data-se="pwd-reset-email-sent"]'))) {
      leadingSibling = document.querySelector('.o-form-explain');
    }

    if (null === leadingSibling) {
      throw new Error('Could not find the leading sibling!');
    }

    result.id = 'hacky-inserter';
    leadingSibling.insertAdjacentElement('afterend', result);
    return result;
  };

  const renderInside = () => {
    return document.getElementById('hacky-inserter') || generateInserter();
  };

  const genEventListener = (type: EventType) => {
    return (context: any) => {
      const { controller } = context;

      const renderTarget = renderInside();
      ReactDOM.render(
        <Root eventType={type} controller={controller} {...cbMaps} />,
        renderTarget
      );
    };
  };

  widget.on('afterRender', genEventListener('afterRender'));
  widget.on('afterError', genEventListener('afterError'));
};