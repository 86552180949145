import { StudentProfileUserData } from '../../../StudentProfile/StudentProfileData';

export enum PromoteState {
  init = 'init',
  verifying = 'verifying',
  verified = 'verified',
  changing = 'changing',
  changed = 'changed',
  error = 'error'
}

export enum UnlinkState {
  init = 'init',
  rename = 'rename',
  success = 'success',
  error = 'error'
}

export interface AltEmailStatus {
  verified: boolean;
}

export interface PromoteFnParams {
  primaryLogin: string;
  setPromoteState: React.Dispatch<React.SetStateAction<PromoteState>>;
  setPromoteError: React.Dispatch<React.SetStateAction<string>>;
  closeModal: () => void;
  secondaryLogin: string;
  testId?: string;
  promoteError?: string;
}

export interface UnlinkFnParams {
  setUnlinkState: React.Dispatch<React.SetStateAction<UnlinkState>>;
  closeModal: () => void;
  login: string;
  testId?: string;
}
