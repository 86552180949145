import React from 'react';
import { Button } from '../magma-wrap/Button';
import { navigateTo, buildPathTo } from '../../navigation/path-builder';
import { CenterAll } from '../styled';
import { Marker } from '../meta/Marker';
import { ALFRED_COOKIES } from '../../frontendBackend';
import { DEV_HELP_GLOBAL } from '../../dev/dev-barrier/safe-ref';
import { FormHeaderInfo } from '../basic/FormHeaderInfo';
import { useQueryParams } from '../hooks/useQueryParams';

export const TEST_ID = {
  MARKER: 'marker-student-landing',

  STUDENT: '5B9F6F0A-B678-4575-8305-2E76F6781813',
  FACULTY: 'F1AE571C-AC57-47CD-BEE1-D1EE1D9D4A74'
};

export const NewFacultyRegisterButton = () => {
  const [institutionId] = useQueryParams()['institutionId'];
  return (
    <Button
      onClick={() => {
        // This transition needs to be covered by e2e as well
        const newLoc = new URL(window.location.toString());
        newLoc.pathname = buildPathTo.onlyPath.facultyRegister.facultyDiscover({ institutionId });
        window.location.href = newLoc.toString();
      }}
      value="No"
      testId={TEST_ID.FACULTY}
      block
    />
  );
};

export interface LandingProps {}

export const Landing: React.FC<LandingProps> = () => {
  /* istanbul ignore next */
  window?.[DEV_HELP_GLOBAL]?.useCommand?.({
    id: 'reg.newFaculty',
    display: 'Toggle New Faculty Reg',
    action: () => {
      const cur = ALFRED_COOKIES.FF.cookieOverride(
        ALFRED_COOKIES.FF.NEW_FACULTY_REG(),
        false
      );
      const repl = !cur;
      ALFRED_COOKIES.FF.setNewFacultyRegister(repl);
      return {
        showMessage: repl ? 'Enabled. Reload page' : 'Disabled. Reload page'
      };
    }
  });

  const registerType = window.backend?.parsedUXmode?.userType?.value;
  const [institutionId] = useQueryParams()['institutionId'];
  if (registerType && 'student' === registerType) {
    navigateTo.register.discover({});
    return <></>;
  } else if (registerType && 'instructor' === registerType) {
    const newLoc = new URL(window.location.toString());
    newLoc.pathname = buildPathTo.onlyPath.facultyRegister.facultyDiscover({ institutionId });
    window.location.href = newLoc.toString();
    return <></>;
  }

  return (
    <>
      <Marker testId={TEST_ID.MARKER} />
      <FormHeaderInfo>Are you a student?</FormHeaderInfo>
      <Button 
        onClick={() => navigateTo.register.discover({})}
        value="Yes"
        testId={TEST_ID.STUDENT}
        block
      />
      <NewFacultyRegisterButton/>
    </>
  );
};