import React from 'react';
import {
  StudentProfileData,
  ProfileDataProps
} from '../screens/StudentProfile/StudentProfileData';
import { BoundaryProps, cleanProps, genPathTo } from './Boundary';
import { useQueryParams } from '../hooks/useQueryParams';
import { StudentProfileFormData } from '../screens/StudentProfile/StudentProfileFormData';
import { featureFlags } from '../../frontendBackend';

const path = 'profile-data';

export interface ProfileDataBoundaryProps
  extends BoundaryProps,
    ProfileDataProps {}

export const StudentProfileDataBoundary: React.FC<ProfileDataBoundaryProps> = props => {
  props = cleanProps(props);

  const [callbackOrigin] = useQueryParams().callbackOrigin;
  const isInIframe = window.backend?.inIframe || (window as any).__inIframeTest;

  return ( 
    <div>
      {!isInIframe && featureFlags.isUseStandaloneProfileForm() ? <StudentProfileFormData callbackOrigin={callbackOrigin} {...props} /> : <StudentProfileData callbackOrigin={callbackOrigin} {...props} />}
    </div>
  );
};

export interface SupportedPathOptions {
  // Currently no options (all props are non-url)
}

export const pathTo = genPathTo<SupportedPathOptions>('/' + path);

StudentProfileDataBoundary.defaultProps = {
  path
};
