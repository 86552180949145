export const getClearedWidgetContent = () => {
  const widgetContent = document.querySelector('.auth-content');
  while (widgetContent?.firstChild) {
    widgetContent.removeChild(widgetContent.firstChild);
  }
  return widgetContent;
};

export const removeLinks = () => {
  const createAccountLinks = document.querySelectorAll('.createAccount');
  createAccountLinks.forEach(link => {
    link.remove();
  });
};

export const removeFooterBackLinkIfExists = () => {
  const footerBackLinkDiv = document.querySelector('.footer-back-link');
  if (footerBackLinkDiv !== null) {
    footerBackLinkDiv.remove();
  }
};

export const removeIcons = () => {
  const inputIcons = document.querySelectorAll('.icon');
  inputIcons.forEach(icon => {
    icon.remove();
  });
};

export const removeRememberMe = (controllerContext: string) => {
  if (controllerContext === 'idp-discovery') {
    document.querySelector('.custom-checkbox')?.remove();
  } else {
    const formFields = document.querySelector('.o-form-fieldset-container');
    formFields?.children[2].remove();
  }
};
