import React from 'react';
import { CreateProfile, CreateProfileProps } from '../CreateProfile';
import { BoundaryProps, genPathTo, cleanProps } from './Boundary';
import { useQueryParams } from '../hooks/useQueryParams';

export const path = '/create-profile';
const paramUsername = 'username';
const paramContextId = 'contextId';

export interface CreateProfileBoundaryProps
  extends BoundaryProps,
    CreateProfileProps {}

export const CreateProfileBoundary: React.FC<CreateProfileBoundaryProps> = props => {
  props = cleanProps(props);

  let [username] = useQueryParams()[paramUsername];
  const [contextId] = useQueryParams()[paramContextId];
  if (!username && contextId){
    username = window.backend?.values.username;
  }
  return <CreateProfile username={username} contextId={contextId} {...props} />;
};

export interface SupportedPathOptions {
  [paramUsername]?: string;
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

CreateProfileBoundary.defaultProps = {
  path
};
