import React from 'react';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { isLastNameValid, ValidatorResponse } from './Validator';
import { ProfileEventTypes, ProfileUpComm } from '../../screens/Profile/ProfileData';

export const minLength = 2;
export const maxLength = 50;
export const TEST_ID = {
  MAIN: 'last-name-input'
};

export interface LastNameInputProps extends ValidatedDataInputProps, ProfileUpComm {
  persistEvent?: boolean
}

export const LastNameInput: React.FC<LastNameInputProps> = props => {
  const { whenValidated, upComm } = props;

  if (upComm?.ctx?.overrides?.values?.[UserDataKeys.lastName]) {
    upComm?.sendMsg({
      type: ProfileEventTypes.dataInit,
      data: {
        key: UserDataKeys.lastName,
        value: upComm.ctx.overrides.values[UserDataKeys.lastName]
      }
    });
  }
  if (upComm?.ctx?.overrides?.hidden?.[UserDataKeys.lastName]) {
    return <React.Fragment></React.Fragment>;
  }
  
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isLastNameValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      defaultValue={upComm?.ctx?.overrides?.values[UserDataKeys.lastName]}
      {...props}
    />
  );
};

LastNameInput.defaultProps = {
  dataLabel: 'Last Name',
  name: UserDataKeys.lastName,
  testId: TEST_ID.MAIN,
  id: 'last-name-input',
  required: true,
  helperMessage: `${maxLength} characters allowed`,
  persistEvent: false,
  whenValidated: () => {return;}
};
