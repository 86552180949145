import React from 'react';
import { FacultyDataKeys } from '../../screens/Profile/FacultyDataKeys';
import { LoginInputProps, LoginInput } from './LoginInput';

export const TEST_ID = { MAIN: 'input-supervisor-email' };

export interface SupervisorEmailInputProps extends LoginInputProps {}

export const SupervisorEmailInput: React.FC<SupervisorEmailInputProps> = props => {
  return (
    <LoginInput
      // Default properties get passed through
      {...props}
    />
  );
};

SupervisorEmailInput.defaultProps = {
  dataLabel: 'Supervisor Email',
  name: FacultyDataKeys.supervisorEmail,
  testId: TEST_ID.MAIN,
  id: 'supervisor-email-input'
};
