import { EventDataHandler, EventPreprocessors } from './shared';
import { ClientEventing } from '@cafe/cafe-eventing';

const addGuid: EventDataHandler = (data = {}) => {
  const windowAny = window as any;
  data.userSSOGUID = windowAny.backend.guid;
  return data;
};

const convertTags: EventDataHandler = (data = {}) => {
  if (data.tags && !Array.isArray(data.tags)) {
    const result: ClientEventing.ActivityTag[] = [];
    for (const key of Object.keys(data.tags)) {
      result.push({
        key,
        value: data.tags[key]
      });
    }
    data.tags = result;
  }
  return data;
};

export const defaultPreProcessors = [convertTags];

/**
 * eventPreprocessors allows for customization of globally known metadata before
 * sending off for a specific event.
 */
export const eventPreprocessors: EventPreprocessors = {
  profileEdit: {
    loadViewPage: [addGuid],
    changePasswordClick: [addGuid],
    saveClick: [addGuid],
    makeAlternateEmailUsernameClick: [addGuid],
    changeData: [addGuid],
    editStarted: [addGuid],
    mouseOverChangeUsername: [addGuid]
  },
  profileEditPassword: {
    loadViewPage: [addGuid],
    saveClick: [addGuid]
  },
  verifyAlternateEmail: {
    loadViewPage: [addGuid],
    resendValidationCodeClick: [addGuid],
    verifyButtonClick: [addGuid],
    changeVerificationCode: [addGuid]
  },
  changeUsernameSave: {
    loadViewPage: [addGuid],
    changeUsernameClick: [addGuid]
  }
};
