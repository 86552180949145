import React from 'react';
import {
  FacultyDiscover,
  FacultyDiscoverProps
} from '../steps/FacultyDiscover';
import { BoundaryProps, genPathTo, cleanProps } from './Boundary';

export const path = '/discover';

export interface FacultyDiscoverBoundaryProps
  extends BoundaryProps,
    FacultyDiscoverProps {}

export const FacultyDiscoverBoundary: React.FC<FacultyDiscoverBoundaryProps> = props => {
  props = cleanProps(props);

  return <FacultyDiscover {...props} />;
};

export interface SupportedPathOptions {
  // Currently no options (upComm is non-url)
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

FacultyDiscoverBoundary.defaultProps = {
  path
};
