import { useEffect, useState } from 'react';

/**
 * useMounted allows for certain callbacks to only be called if
 * the component is mounted. In most cases, this should not be used.
 * It can be used in situations where the standard useEffect/callback
 * pattern does not work. Try your hardest not to use this.
 */
export const useMounted = () => {
  const [wrapper] = useState({ isMounted: false });
  useEffect(() => {
    wrapper.isMounted = true;
    return () => {
      wrapper.isMounted = false;
    };
  });

  return {
    ifMounted: (cb: () => void) => {
      if (wrapper.isMounted) {
        cb();
      }
    }
  };
};
