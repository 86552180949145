import React from 'react';

export type EventType = 'afterRender' | 'afterError';

export type CallbackMap = {
  'primary-auth'?: () => React.ReactNode;
  'forgot-password'?: () => React.ReactNode;
  'password-reset-email-sent'?: () => React.ReactNode;
  'recovery-loading'?: () => React.ReactNode;
};
type SuppCont = keyof CallbackMap;

export interface CallbackMaps {
  afterRender?: CallbackMap;
  afterError?: CallbackMap;
}

export interface HackyGUIProps extends CallbackMaps {
  controller?: SuppCont;
  eventType?: EventType;
}

export const HackyGUI: React.FC<HackyGUIProps> = props => {
  const render =
    props.eventType &&
    props.controller &&
    props[props.eventType]?.[props.controller]?.();

  return <>{render || <></>}</>;
};