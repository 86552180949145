import { OktaController } from './shared';
import { createStudentLinkId } from './dom-build';

const $ = (sel: string) => document.querySelector(sel);

// Attribute to use when modifying an existing widget element
// in order to label its new use (useful for checking that the)
// mod actually worked and for describing what we changed it to
export const DOM_FIND_ATTR = 'data-widget-dom-find';

// The value for the DOM_FIND_ATTR which should be descriptive
// of its new purpose
export enum DomFindPrints {
  backToSignIn = 'back-to-sign-in',
  notRedirected = 'not-redirected-automatically'
}

const domFindSelector = (domFindPrint: DomFindPrints) => {
  return `[${DOM_FIND_ATTR}="${domFindPrint}"]`;
};

const primaryAuthAndIDP = {
  button: {
    // Button pressed to actually login
    login: () => $('[type="submit"][data-type="save"]')
  },
  link: {
    createStudentAccount: () => $('#' + createStudentLinkId),
    // Link used to expand the need help menu
    needHelpSigningIn: () => $('[data-se="needhelp"]'),
    // Actual link to help, not to be confused with needHelpSigningIn
    helpLink: () => $('[data-se="help-link"]'),
    forgotPassword: () => $('[data-se="forgot-password"]')
  },
  input: {
    login: () => $('input[name="username"]')
  }
};

export const DomFind = {
  [OktaController.primaryAuth]: primaryAuthAndIDP,
  [OktaController.idpDiscovery]: primaryAuthAndIDP,
  [OktaController.passwordReset]: {
    changePassword: () => $('[data-type="save"]'),
    returnToSignIn: () => $(domFindSelector(DomFindPrints.backToSignIn))
  },
  [OktaController.forgotPassword]: {
    loginInput: (): HTMLInputElement => $('[name="username"]') as any,
    resetViaEmail: () => $('[data-se="email-button"]'),
    backLink: () => $('[data-se="back-link"]')
  }
};
