import {
  DEV_HELP_GLOBAL,
  CommandInfoMapCB,
  CommandInfoMap,
  CommandInfo
} from './safe-ref';
import { useEffect, useLayoutEffect } from 'react';

const supportedHostnames = ['localhost', 'dev-alfred.cengage.info'];

export const cookieKey = 'iam_dev_help_enabled';

const isSupported = () => {
  const { hostname } = document.location;
  const [, cookieValue] =
    document.cookie
      .split(';')
      .map(v => v.split('='))
      .filter(([key]) => cookieKey === key.trim())[0] || [];

  if ('true' === cookieValue) {
    return true;
  } else if ('false' === cookieValue) {
    return false;
  }
  for (const supported of supportedHostnames) {
    if (hostname === supported) {
      return true;
    }
  }
  return false;
};

if (document && document.location?.search.includes('devhelp')) {
  document.cookie = 'iam_dev_help_enabled=true;path=/';
  (document as any).location = document.location
    .toString()
    // If only query param, replace question mark as well,
    // otherwise replace & delimiter. It is expected to be
    // at the end of the url.
    .replace(/(\?|&)devhelp(=enable)?$/, '');
}

interface DevRegister {
  register: () => Promise<void>;
}

export const attemptRegisterForDevs = () => {
  try {
    if (isSupported()) {
      // eslint-disable-next-line no-console
      console.log(
        `%c
      .==========================================.
      |                                          |
      | Installing dev-help                      |
      |                                          |
      '=========================================='
      `,
        'color: #1d7288; font-family:monospace; font-weight: bolder'
      );

      // Need to setup the command handling here, otherwise there will be
      // a race condition.

      // Listeners for command changes
      const listeners: CommandInfoMapCB[] = [];
      // Commands that are registered temporarily
      const commands: CommandInfoMap = {};

      const notifyListeners = () => {
        // This causes any state updates to notice changes
        const notifyCommands = { ...commands };
        for (const listener of listeners) {
          listener(notifyCommands);
        }
      };

      const addCommand = (info: CommandInfo) => {
        commands[info.id] = info;
        notifyListeners();
      };
      const removeCommand = (info: CommandInfo) => {
        delete commands[info.id];
        notifyListeners();
      };

      window[DEV_HELP_GLOBAL] = {
        commands,

        useCommand: async (info: CommandInfo) => {
          useLayoutEffect(() => {
            addCommand(info);
            return () => removeCommand(info);
          });
        },

        useForCommands: async (cb: (commands: CommandInfoMap) => void) => {
          useEffect(() => {
            listeners.push(cb);
            return () => {
              listeners.splice(listeners.indexOf(cb), 1);
            };
          });
        }
      };

      // If bundle is not loading correctly double check src/utility/customize-webpack.ts
      import(/* webpackChunkName: "devHelp" */ '../dev-help')
        .then((mod: DevRegister) => {
          return mod.register();
        })
        .catch(ignoreErrors => console.log(ignoreErrors));
    }
  } catch (ignoreErrors) {
    // Ignore errors
    console.log(ignoreErrors);
  }
};
