import * as React from 'react';

/* istanbul ignore next */
const globalLocation = () => {
  if (window && window.location && window.location.search) {
    return window.location.search;
  }
  return undefined;
};

export interface LinkProps {
  className?: string;
  copyParams?: string[];
  href?: string;
  params?: { [key: string]: any };
  target?: string;
  id?: string;
  testId?: string;

  testingLocation?: string;
  onClick?: React.MouseEventHandler;
}

export class Link extends React.Component<LinkProps, any> {
  constructor(props: LinkProps) {
    super(props);
  }

  // tslint:disable-next-line:cyclomatic-complexity
  public render(): React.ReactNode {
    const { onClick: onClickProps } = this.props;
    const params: { [key: string]: any } = this.props.params || {};

    // Allow for easy testing without modifying the global scope
    const searchString = (
      this.props.testingLocation ||
      globalLocation() ||
      ''
    ).substr(1);

    if (searchString && this.props.copyParams) {
      const copyParams = this.props.copyParams;
      const location = new URLSearchParams(searchString);
      for (const copyParam of copyParams) {
        const value = location.get(copyParam);
        if (value) {
          // If found, copy over property
          params[copyParam] = value;
        }
      }
    }

    let search = new URLSearchParams(params).toString();
    if (search) {
      search = '?' + search;
    }

    const href = this.props.href ? this.props.href + search : '#';
    const linkPrevent = '#' === href;
    return (
      <a
        href={href}
        target={this.props.target}
        className={this.props.className}
        onClick={e => {
          onClickProps?.(e);
          if (linkPrevent) {
            return false;
          }
        }}
        id={this.props.id}
        data-testid={this.props.testId}
      >
        {this.props.children}
      </a>
    );
  }
}
