export enum UserDataKeys {
  accountName = 'accountName',
  login = 'loginId',
  email = 'email',
  alternateLogin = 'alternateLoginId',
  phoneNumber = 'phoneNumber',
  firstName = 'firstName',
  lastName = 'lastName',
  preferredName = 'preferredName',
  birthYear = 'birthYear',
  marketOptIn = 'marketOptIn',
  passwordLastChanged = 'passwordLastChanged',
  timezone = 'timezone',
  instId = 'institutionId',
  instName = 'institutionName',
  loginId = 'username',
  termsAndConditions = 'termsAndConditions',
  marketingOptIn = 'marketingOptIn',
  institutionName = 'institutionName',
  skipEmailVerification= 'skipEmailVerification',
  contextId= 'contextId',
  autoVerifyUser = 'autoVerifyUser',
  userType = 'userType'
}