import React from 'react';
import { Discover, DiscoverProps } from '../steps/Discover';
import { BoundaryProps, genPathTo, cleanProps } from './Boundary';

export const path = '/discover';

export interface DiscoverBoundaryProps extends BoundaryProps, DiscoverProps {}

export const DiscoverBoundary: React.FC<DiscoverBoundaryProps> = props => {
  props = cleanProps(props);

  return <Discover {...props} />;
};

export interface SupportedPathOptions {
  // Currently no options (upComm is non-url)
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

DiscoverBoundary.defaultProps = {
  path
};
