import React, { useState } from 'react';
import { Alert, AlertVariant } from 'react-magma-dom';
import { UserDataKeys } from '../../UserDataKeys';
import { PromoteState, PromoteFnParams } from './shared';
import { VerifyCode } from './VerifyCode';

export const PromoteStateVerifying: React.FC<PromoteFnParams> = ({
  primaryLogin,
  setPromoteState,
  testId, 
  secondaryLogin
}) => {
  const [err, setErr] = useState('');

  const wrapProps: any = {};
  if (testId) {
    wrapProps['data-testid'] = testId;
  }

  return (
    <div {...wrapProps}>
      <p>
        We sent an email to{' '}
        <span className="snippet">{secondaryLogin}</span>
        . Please enter the verification code from the email and press{' '}
        <b>verify</b> to validate your email.
      </p>
      {err && (
        <Alert
          variant={AlertVariant.warning}
          onDismiss={setErr.bind(null, '')}
        >
          {err}
        </Alert>
      )}
      <VerifyCode
        email={secondaryLogin}
        onFailure={error => setErr("Your code does not match our records. Please try again.")}
        onVerified={() => setPromoteState(PromoteState.verified)}
      />
    </div>
  );
};
