import { pathTo as discoverPath } from '../components/boundaries/DiscoverBoundary';
import { pathTo as createProfilePath } from '../components/boundaries/CreateProfileBoundary';
import { pathTo as emailSentPath } from '../components/boundaries/EmailSentBoundary';
import { pathTo as facultyEmailSentPath } from '../components/boundaries/FacultyEmailSentBoundary';
import { pathTo as profileDataPath } from '../components/boundaries/StudentProfileDataBoundary';
import { navigate } from '@reach/router';
import {
  manualVerificationPathTo,
  pathTo as facultyCreateProfilePath
} from '../components/boundaries/FacultyCreateProfileBoundary';
import { pathTo as facultyDiscoverPath } from '../components/boundaries/FacultyDiscoverBoundary';
import { pathTo as facultyProfileDataPath } from '../components/boundaries/FacultyProfileDataBoundary';
import { pathTo as landingPath } from '../components/boundaries/LandingBoundary';
import { pathTo as facultyLandingPath } from '../components/boundaries/FacultyLandingBoundary';
import { pathTo as studentSchoolSearchPath } from '../components/boundaries/StudentSchoolSearchBoundary';
import { toQueryString } from '../utility';

const prefixWith = <T extends any>(prefix: string, fn: (opt: T) => string) => {
  return (options: T) => {
    return prefix + fn(options);
  };
};

const registerPath = '/register';
const profilePath = '/profile';
const facultyRegisterPath = '/faculty-register';
const facultyProfilePath = '/faculty-profile';

export const onlyPath = (builtPath: string) => {
  return builtPath.split('?')[0];
};

/**
 * Wraps all callbacks in the given object with onlyPath
 * which returns the path without the query params.
 * @param target Target to copy
 */
export const wrapWithOnlyPath = <T extends Record<string, any>>(
  target: T
): T => {
  const result = { ...target };

  const getEntries = (orig: any, copyTo: any) => {
    return Object.entries(orig).map(([k, v]) => {
      return {
        orig,
        copyTo,
        key: k,
        value: v
      };
    });
  };

  const toProcess = getEntries(target, result);
  for (const { orig, copyTo, key, value } of toProcess) {
    if ('function' === typeof value) {
      // Wrap with onlyPath
      copyTo[key] = (...args: any[]) => onlyPath(value(...args));
    } else if ('object' === typeof value) {
      copyTo[key] = {};
      toProcess.push(...getEntries(orig[key], copyTo[key]));
    }
  }

  return result;
};

const buildPathToSeed = {
  register: {
    root: () => registerPath + toQueryString({}),
    discover: prefixWith(registerPath, discoverPath),
    createProfile: prefixWith(registerPath, createProfilePath),
    emailSent: prefixWith(registerPath, emailSentPath),
    landing: prefixWith(registerPath, landingPath),
    studentSchoolSearch: prefixWith(registerPath, studentSchoolSearchPath)
  },
  profile: {
    root: () => profilePath + toQueryString({}),
    profileData: prefixWith(profilePath, profileDataPath)
  },
  facultyRegister: {
    root: () => facultyRegisterPath + toQueryString({}),
    facultyDiscover: prefixWith(facultyRegisterPath, facultyDiscoverPath),
    facultyCreateProfile: prefixWith(
      facultyRegisterPath,
      facultyCreateProfilePath
    ),
    internationalFacultyCreateProfile: prefixWith(
      facultyRegisterPath, 
      manualVerificationPathTo
    ),
    facultyEmailSent: prefixWith(facultyRegisterPath, facultyEmailSentPath),
    facultyLanding: prefixWith(facultyRegisterPath, facultyLandingPath)
  },
  facultyProfile: {
    root: () => facultyProfilePath + toQueryString({}),
    profileData: prefixWith(facultyProfilePath, facultyProfileDataPath)
  }
};

export const buildPathTo = {
  onlyPath: wrapWithOnlyPath(buildPathToSeed),
  ...buildPathToSeed
};

const navigateWrap = <T extends any>(buildFn: (opt: T) => string) => {
  return (options: T) => {
    const path = buildFn(options);
    navigate(path);
  };
};

export const navigateTo = {
  register: {
    root: navigateWrap(buildPathTo.register.root),
    discover: navigateWrap(buildPathTo.register.discover),
    createProfile: navigateWrap(buildPathTo.register.createProfile),
    emailSent: navigateWrap(buildPathToSeed.register.emailSent),
    landing: navigateWrap(buildPathTo.register.landing),
    studentSchoolSearch: navigateWrap(buildPathTo.register.studentSchoolSearch)
  },
  profile: {
    root: navigateWrap(buildPathTo.profile.root),
    profileData: navigateWrap(buildPathTo.profile.profileData)
  },
  facultyRegister: {
    root: navigateWrap(buildPathTo.facultyRegister.root),
    facultyDiscover: navigateWrap(buildPathTo.facultyRegister.facultyDiscover),
    facultyCreateProfile: navigateWrap(
      buildPathTo.facultyRegister.facultyCreateProfile
    ),
    internationalFacultyCreateProfile: navigateWrap(
      buildPathTo.facultyRegister.internationalFacultyCreateProfile
    ),
    facultyEmailSent: navigateWrap(
      buildPathTo.facultyRegister.facultyEmailSent
    ),
    facultyLanding: navigateWrap(buildPathTo.facultyRegister.facultyLanding)
  },
  facultyProfile: {
    root: navigateWrap(buildPathTo.facultyProfile.root),
    facultyProfileData: navigateWrap(buildPathTo.facultyProfile.profileData)
  }
};
