import React from 'react';
import { FacultyCreateProfile, FacultyCreateProfileProps } from '../FacultyCreateProfile';
import { BoundaryProps, genPathTo, cleanProps } from './Boundary';
import { useQueryParams } from '../hooks/useQueryParams';

export const autoVerificationPath = '/profile-auto-verify';
export const manualVerificationPath = '/profile-manual-verify';
const paramUsername = 'username';
const paramInstitutionId = 'institutionId';

export interface FacultyCreateProfileBoundaryProps extends BoundaryProps, FacultyCreateProfileProps {}

export const FacultyCreateProfileBoundary: React.FC<FacultyCreateProfileBoundaryProps> = props => {
  props = cleanProps(props);

  const [username] = useQueryParams()[paramUsername];
  const [institutionId] = useQueryParams()[paramInstitutionId];
  return <FacultyCreateProfile username={username} institutionId={institutionId} {...props} />;
};

export interface SupportedPathOptions {
  [paramUsername]?: string;
  [paramInstitutionId]?: string;
}

export const pathTo = genPathTo<SupportedPathOptions>(autoVerificationPath);
export const manualVerificationPathTo = genPathTo<SupportedPathOptions>(manualVerificationPath);

FacultyCreateProfileBoundary.defaultProps = {
  path: autoVerificationPath
};
