export enum FacultyDataKeys {
  entityName = 'entityName',
  entityCity = 'entityCity',
  entityState = 'entityState',
  entityCountry = 'entityCountry',
  entityRegion = 'entityRegion',
  supervisorName = 'supervisorName',
  supervisorEmail = 'supervisorEmail',
  supervisorPhone = 'supervisorPhone'
}
