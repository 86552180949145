import React, {useState} from 'react';
import { CengageLogoTemplate } from "../styled";

export interface CengageLogoProps {
  leftAlign?: boolean;
}

export const cengageLogoUrl = () => `${window.backend.emailUrl}/logo-cengage.png`;

export const cengageLogoAlt = "Cengage Learning Logo"

export const CengageLogo: React.FC<CengageLogoProps> = props => {
  return (
    <CengageLogoTemplate leftAlign={props.leftAlign} src={cengageLogoUrl()} alt={cengageLogoAlt}/>
  );
};