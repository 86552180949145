/** @jsx jsx */
import React, { useEffect, useState, useMemo } from 'react';
import { jsx, css } from '@emotion/core';
import { Input, Tooltip, IconButton, ButtonVariant } from 'react-magma-dom';
import { ProfileEventTypes, ProfileUpComm } from '../ProfileData';
import { InputProps } from 'react-magma-dom/dist/components/Input';
import { HelpIcon } from 'react-magma-icons';

export const TEST_ID = {
  TOOLTIP: 'data-input-tooltip'
};

export interface DataInputProps
  extends ProfileUpComm,
    Omit<InputProps, 'labelText'> {
  dataLabel: string;
  name?: string;

  skipChangeNotify?: boolean;

  readonly?: boolean;

  tooltip?: React.ReactNode;

  // Validator runs on each change and if it throws an Error prevents
  // the change callbacks from running
  validator?: (value: string) => Promise<void>;

  // When we blur the input and there is a validation error:
  onErrorBlur?: (error: string) => void;

  // Used to populate the help icon
  ariaHelpButton?: string;
  extraNodes?: React.ReactNode[];
  persistEvent?: boolean;
  registerErrorMessage?: string;
}

export const DataInput: React.FunctionComponent<DataInputProps> = props => {
  const {
    dataLabel,
    name,
    required,
    onChange,
    onBlur,
    skipChangeNotify,
    readonly,
    validator,
    upComm,
    helperMessage,
    onErrorBlur,
    tooltip: tooltip,
    ariaHelpButton = 'More info',
    // Extra nodes should provide a key property for helping with array-based rendering
    extraNodes,
    persistEvent = false,
    registerErrorMessage,
    ...rest
  } = props;
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    // This effect is meant to send a message telling the target
    // UpComm that this field is required.
    upComm?.sendMsg?.({
      type: ProfileEventTypes.dataRequired,
      data: {
        key: name,
        value: required,
        label: dataLabel
      }
    });
  }, [dataLabel, name, required, upComm]);

  useEffect(() => {
    return () => {
      upComm?.sendMsg?.({
        type: ProfileEventTypes.dataRequired,
        data: {
          key: name,
          value: false,
          label: dataLabel
        }
      });
    };

    // Want to be sure to handle full mount/unmount, not
    // based on dependency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    if (registerErrorMessage) {
      setErrorMessage(registerErrorMessage);
    }
  }, [registerErrorMessage]);

  const extraInfo = useMemo(() => {
    const result: React.ReactNode[] = [
      // This is an optional help button which will
      // place itself beside the input if there is a tooltip to display
      tooltip && (
        <Tooltip
          key="extra-tooltip"
          content={tooltip}
          testId={rest.testId + '-' + TEST_ID.TOOLTIP}
          // This is a hack in order to fit within the constraints of the browser edge.
          // We chose not to move the tooltip to the left because it completely
          // covers the input and makes it look strange.
          tooltipStyle={{ maxWidth: '150px' }}
        >
          <IconButton
            aria-label={ariaHelpButton}
            icon={<HelpIcon />}
            variant={ButtonVariant.link}
            // This is a hack to get around mobile-based interaction with tooltips.
            // Since we can't control whether or not the tooltip is showing, we
            // need to work within the constraints of focusing. If this is clicked
            // by a mobile user, it focuses and causes the tooltip to show.
            onClick={e => e?.currentTarget?.focus()}
          />
        </Tooltip>
      ),
      ...(extraNodes ?? [])
    ].filter(v => v);
    return result;
  }, [ariaHelpButton, extraNodes, rest.testId, tooltip]);

  return (
    <React.Fragment>
      <div
        // [temp readonly] bad solution, but will have to do for now
        data-readonly={readonly}
        // Magma doesn't support className for the Input wrapper
        // so we will have to use a parent div for now
        className={required ? 'required-input' : ''}
        css={css`
          display: flex-wrap;
          margin-bottom: 15px;
          // [temp readonly] bad solution, but will have to do for now
          &[data-readonly] input[disabled],
          &[readonly] input {
            background-color: #eee;
            color: #131313;
          }
        `}
      >
        <Input
          containerStyle={{ flex: '1 0 auto' }}
          labelText={dataLabel}
          errorMessage={errorMessage}
          helperMessage={!readonly && helperMessage}
          required={required}
          onBlur={e => {
            onBlur?.(e);
            if (errorMessage) {
              onErrorBlur?.(errorMessage);
            }
          }}
          onChange={async e => {
            if (onChange) {
              onChange?.(e);
            } else {
              // Conditional will be investigated in future ticket to improve performance on profile pages
              if (persistEvent) {
                e.persist();
              }
              const { value } = e.target;
              if (validator) {
                try {
                  await validator(value);
                } catch (error: any) {
                  setErrorMessage(error.message);
                  upComm?.sendMsg?.({
                    type: ProfileEventTypes.dataInvalid,
                    data: {
                      key: name
                    }
                  });
                  // Don't continue, validation failed
                  return;
                }
              }
              // Reset any errors there might have been previously
              setErrorMessage('');

              return (
                !skipChangeNotify &&
                name &&
                upComm?.sendMsg?.({
                  type: ProfileEventTypes.dataChanged,
                  data: {
                    key: name,
                    value: value
                  }
                })
              );
            }
          }}
          // [temp readonly] bad solution, but will have to do for now
          disabled={readonly}
          {...rest}
          name={name}
        >
          {extraInfo}
        </Input>
      </div>
    </React.Fragment>
  );
};
