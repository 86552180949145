import styled from '@emotion/styled';

import { Link as BasicLink, LinkProps } from '../basic/Links';

export const Container = styled.div`
  display: block;
`;
export const ContentContainer = styled.div`
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  background-color: #f9f9f9;
  color: #3f3f3f;
  position: relative;
  overflow: visible;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  height: auto;
  margin: 100px auto 8px;
  width: 600px;
  min-width: 600px;
  background-color: #fff;
  border-color: #ddd #ddd #d8d8d8;
  @media only screen and (max-width: 600px) {
    border: 0;
    box-shadow: none;
    width: auto;
    margin: 0;
  }

  @media only screen and (max-height: 750px) {
    margin-top: 0;
  }
`;
export const WidgetHeader = styled.div`
  padding: 30px 0px 30px;
  position: relative;
  border-bottom: 1px solid #ddd;
  z-index: 10;
`;
export const CengageLogoTemplate = styled.img<{ leftAlign?: boolean }>`
  display: block;
  margin: ${props => (props.leftAlign ? '0 0' : '0 auto')};
  max-width: 400px;
  max-height: 80px;
`;
export const SheerIDLogo = styled.img`
  display: block;
  max-width: 60px;
  max-height: 28px;
  margin-top: 8px;
`;
export const WidgetContent = styled.div`
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
  transition: padding-top 0.4s;
`;
export const WidgetContentInner = styled.div`
  margin: 0;
  padding: 0 15px;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
`;
export const ErrorHeader = styled.h1`
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  text-transform: none;
  padding-top: 10px;
  color: #003865;
`;
export const Input = styled.input`
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
  padding: 6px 10px;
  border: 1px solid #bbb;
  border-radius: 3px;
  box-sizing: border-box;
`;
export const InputErrorText = styled.span`
  font-size: 13px;
`;
export const Button = styled.input`
  color: #fff;
  background-color: #006298;
  background: #006298;
  background-color: #006298;
  border-color: #006298;
  border-bottom-color: #006298;
  border-radius: 3px;
  border-width: 0px;
  display: block;
  width: 100%;
  height: 50px;
  font-family: 'montserrat', Arial, Helvetica, sans-serif;
  font-size: 13px;
  margin-top: 10px;
  :hover {
    background-color: #003865;
    background: #003865;
  }
  :focus {
    outline: 2px solid #f2a900;
  }
  :disabled {
    opacity: 0.5;
  }
`;
export const LegalStatement = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

export const SignInFooter = styled.div`
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding: 20px 0;
`;

export const Link = styled(BasicLink)<LinkProps>`
  color: #3942B0;
  text-decoration: none;
  :hover,
  :active {
    color: #003865;
    text-decoration: underline;
  }
  :focus {
    outline: 2px solid #f2a900;
    text-decoration: underline;
  }
`;

export const ErrorSpan = styled.span`
  &::before {
    content: '\\e055';
    color: #fff;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: okticon;
    font-size: 16px;
    font-smoothing: antialiased;
    font-style: normal !important;
    font-weight: 300 !important;
    speak: none;
    text-indent: 0;
    white-space: normal;
  }
`;
export const FooterLink = styled.li`
display: inline-block;
    white-space: nowrap;
    list-style: none;
    padding: 20px;
`;
/**
 * Makes it so that all children will be as centered as possible
 * and will grow to fill the space.
 */
export const CenterAll = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  & > * {
    flex-grow: 1;
  }
`;

/**
 * Center without growing the input
 */
export const CenterButton = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
