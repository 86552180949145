import {
  OktaWidget,
  OktaEventContext,
  OktaErrorData,
  OktaController
} from './shared';
import { trackingClient } from '../../ext/tracking';
import { DomFind } from './dom-find';
import { Flow } from './flow';

interface KeySource {
  name: string;
  message: string;
}

const genKey = ({ name, message }: KeySource) => {
  return `${name}: ${message}`;
};

interface TrackingTarget {
  target: () => Element | null;
  events: string[];
  action: () => void;
}

// Registers tracking events on specific targets
const registerEvents = (targets: TrackingTarget[]) => {
  for (const { target, events, action } of targets) {
    const resolvedTarget = target();
    if (resolvedTarget) {
      for (const event of events) {
        resolvedTarget.addEventListener(event, action);
      }
    } else {
      console.warn(
        `[!] Had trouble adding a listener for: [${event}] ${target}`
      );
    }
  }
};

const unloadEventArray: (() => void)[] = [];
export const addUnloadEvent = (callback: () => void) => {
  unloadEventArray.push(callback);
};

export const registerTracking = (widget: OktaWidget) => {
  // Used for adding hooks for clicks, etc.
  widget.on('afterRender', (context: OktaEventContext) => {
    const { controller } = context;

    if (
      OktaController.primaryAuth === controller ||
      OktaController.idpDiscovery === controller
    ) {
      const find = DomFind[controller];
      const events = ['click'];
      const category = trackingClient.event.signIn;
      registerEvents([
        {
          events,
          target: find.button.login,
          action: () => category.loginButtonClick()
        },
        {
          events,
          target: find.link.createStudentAccount,
          action: () => category.createNewStudentAccountClick()
        },
        {
          events,
          target: find.link.forgotPassword,
          action: () => category.forgotPasswordClick()
        },
        {
          events,
          target: find.link.helpLink,
          action: () => category.helpLinkClick()
        },
        {
          events,
          target: find.link.needHelpSigningIn,
          action: () => category.needHelpSigningInClick()
        }
      ]);
    } else if (OktaController.passwordReset === controller) {
      const find = DomFind[controller];
      // Have to fingerprint here (unfortunately)
      if (Flow.isAddPassword()) {
        const events = ['click'];
        const category = trackingClient.event.activateWithPassword;
        registerEvents([
          {
            events,
            target: find.changePassword,
            action: () => category.savePasswordClick()
          }
        ]);
      } else if (Flow.isResetPassword()) {
        const events = ['click'];
        const category = trackingClient.event.resetPassword;
        registerEvents([
          {
            events,
            target: find.changePassword,
            action: () => category.resetPasswordClick()
          },
          {
            events,
            target: find.returnToSignIn,
            action: () => category.returnToSignInClick()
          }
        ]);
      }
    } else if (OktaController.forgotPassword === controller) {
      const find = DomFind[controller];
      const events = ['click'];
      const category = trackingClient.event.resetPasswordStart;
      registerEvents([
        {
          events,
          target: find.resetViaEmail,
          action: () =>
            category.sendPasswordResetClick({
              tags: {
                login: find.loginInput()?.value
              }
            })
        },
        {
          events,
          target: find.backLink,
          action: () => category.returnToSignInClick()
        }
      ]);
    }
  });

  const loadEvents = ({ controller }: { controller: string }) => {
    if (
      OktaController.primaryAuth === controller ||
      OktaController.idpDiscovery === controller
    ) {
      trackingClient.event.signIn.loadViewPage();
      addUnloadEvent(() => trackingClient.event.signIn.unloadViewPage());
    } else if (OktaController.passwordReset === controller) {
      if (Flow.isAddPassword()) {
        trackingClient.event.activateWithPassword.loadViewPage();
        addUnloadEvent(() =>
          trackingClient.event.activateWithPassword.unloadViewPage()
        );
      } else if (Flow.isResetPassword()) {
        trackingClient.event.resetPassword.loadViewPage();
        addUnloadEvent(() =>
          trackingClient.event.resetPassword.unloadViewPage()
        );
      }
    } else if (OktaController.forgotPassword === controller) {
      trackingClient.event.resetPasswordStart.loadViewPage();
      addUnloadEvent(() =>
        trackingClient.event.resetPasswordStart.unloadViewPage()
      );
    }
  };

  // Tracking page loads/unloads
  widget.on('afterRender', (context: OktaEventContext) => {
    const { controller } = context;

    loadEvents({ controller });

    // This will only handle transitions within the widget and won't handle
    // tab closes / browser navigation. It also assumes that the render
    // of the new view succeeds... Very fragile, should use an observer
    // to track when the widget transitions views and an unload handler
    // to handle when the browser navigates.
    for (const unloadEvent of unloadEventArray.splice(0)) {
      try {
        unloadEvent();
      } catch (error) {
        console.warn('[!] Issue when running unload event', error);
      }
    }
  });

  // Tracking errors
  widget.on('afterError', (context: OktaEventContext, error: OktaErrorData) => {
    const { controller } = context;
    const key = genKey(error);

    if (
      genKey({ name: 'AuthApiError', message: 'Authentication failed' }) === key
    ) {
      if (OktaController.primaryAuth === controller) {
        trackingClient.event.signIn.failedLogin();
      }
    }
  });
};
