import React from 'react';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { isBirthYearValid, ValidatorResponse } from './Validator';
import { InputIconPosition } from 'react-magma-dom';
import { ProfileEventTypes, ProfileUpComm } from '../../screens/Profile/ProfileData';

const thisYear = new Date().getFullYear();

export const minLength = 4;
export const youngestYear = thisYear - 13;
export const TEST_ID = {
  MAIN: 'birth-year-input'
};

export interface BirthYearInputProps extends ValidatedDataInputProps, ProfileUpComm {
  persistEvent?: boolean
}

export const BirthYearInput: React.FC<BirthYearInputProps> = props => {
  const { whenValidated, upComm } = props;

  if (upComm?.ctx?.overrides?.values?.[UserDataKeys.birthYear]) {
    upComm?.sendMsg({
      type: ProfileEventTypes.dataInit,
      data: {
        key: UserDataKeys.birthYear,
        value: upComm.ctx.overrides.values[UserDataKeys.birthYear]
      }
    });
  }
  if (upComm?.ctx?.overrides?.hidden?.[UserDataKeys.birthYear]) {
    return <React.Fragment></React.Fragment>;
  }
  
  return (
    <DataInput
      iconPosition={InputIconPosition.top}
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isBirthYearValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      defaultValue={upComm?.ctx?.overrides?.values[UserDataKeys.birthYear]}
      {...props}
    />
  );
};

BirthYearInput.defaultProps = {
  ariaHelpButton: 'Why do we collect this?',
  tooltip:
    'We collect this for GDPR and privacy compliance only. We do not share this information',
  dataLabel: 'Birth Year',
  name: UserDataKeys.birthYear,
  testId: TEST_ID.MAIN,
  id: 'birth-year-input',
  required: true,
  helperMessage: '4 numbers, no spaces',
  persistEvent: false,
  whenValidated: () => {return;}
};
