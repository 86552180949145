import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { AsyncSelect } from '../../../magma-wrap/AsyncSelect';
import {
  HasInCommon,
  queryRipsawInstitutionsForSelect,
  UserType
} from '../../../../api';
import { ProfileUpComm, ProfileEventTypes } from '../ProfileData';
import { UserDataKeys } from '../UserDataKeys';
import { Alert, AlertVariant } from 'react-magma-dom';
import { Option } from '../../../magma-wrap/shared';

export const TEST_ID = {
  SELECT_INSTITUTION_PICKER: 'institution-picker'
};
export const TEST_ID_ERROR = TEST_ID.SELECT_INSTITUTION_PICKER + '-error';
export const NAME = 'institution';
const LABEL = 'Institution';

export interface InstitutionPickerProps extends ProfileUpComm {
  instId?: string;
  instName?: string;
  required?: boolean;
  disabled?: boolean;
  userType?: UserType;
  hasInCommon?: HasInCommon;
  name?: string;

  onError?: () => void;
  onFocus?: () => void;
  onChange?: (option: Option) => void;
  itemListMaxHeight?: string;
  helperMessage?: string;
  errorMessage?: string;
  alternateLoginFlow?: boolean;
}

export const InstitutionPicker: React.FC<InstitutionPickerProps> = props => {
  const {
    upComm,
    onFocus,
    required,
    instId,
    instName,
    onChange,
    disabled,
    userType,
    onError,
    hasInCommon,
    itemListMaxHeight,
    helperMessage,
    errorMessage,
    alternateLoginFlow
  } = props;

  const hasOverrides = !!upComm?.ctx?.overrides?.hidden?.[UserDataKeys.instId];
  const [error, setError] = useState(null as Error | null);
  const [isRequired, setRequiredOverride] = useState(required);
  const [renderedErrorMessage, setRenderedErrorMessage] = useState(errorMessage);
  useEffect(() => { setRenderedErrorMessage(errorMessage); }, [errorMessage] );

  const initItems = useMemo(() => Promise.resolve([]), []);
  const initValue = useMemo(() => {
    return (
      (instName &&
        instId &&
        Promise.resolve({ label: instName, item: instId })) ||
      undefined
    );
  }, [instId, instName]);

  const loadOptions = useCallback(
    async (query: string) => {
      if (3 > query.length) {
        return [];
      }

      return queryRipsawInstitutionsForSelect(query, userType, hasInCommon);
    },
    [userType, hasInCommon]
  );

  useEffect(() => {
    // This effect is meant to send a message telling the target
    // UpComm that this field is required.
    upComm?.sendMsg({
      type: ProfileEventTypes.dataRequired,
      data: {
        key: UserDataKeys.instId,
        value: isRequired && !disabled,
        label: LABEL
      }
    });
    if (!hasOverrides) {
      upComm?.sendMsg({
        type: ProfileEventTypes.dataRequired,
        data: {
          key: UserDataKeys.instName,
          value: isRequired && !disabled,
          label: LABEL
        }
      });
    }
  }, [isRequired, upComm, disabled, hasOverrides]);

  if (hasOverrides) {
    // Send the id
    upComm?.sendMsg({
      type: ProfileEventTypes.dataChanged,
      data: {
        key: UserDataKeys.instId,
        value: upComm?.ctx?.overrides?.values?.[UserDataKeys.instId]
      }
    });
    return <></>;
  }

  return (
    <div className={isRequired ? 'required-input' : ''} style={{ marginBottom: 15 }}>
      {(!error || alternateLoginFlow) && (
        <AsyncSelect
          loadOptions={async (query: string) => {
            return loadOptions(query).catch(e => {
              setError(e);
              if (onError) {
                onError();
              }
              // No longer required
              setRequiredOverride(false);
              return [];
            });
          }}
          labelText={LABEL}
          id="institution-picker"
          placeholder="Search by institution name or location."
          debounceMS={350}
          onInputFocus={onFocus}
          onSelectedItemChange={changes => {
            if (changes.selectedItem) {
              const { label, item } = changes.selectedItem;

              // Send the id
              upComm?.sendMsg({
                type: ProfileEventTypes.dataChanged,
                data: {
                  key: UserDataKeys.instId,
                  value: item
                }
              });
              // Send the name
              upComm?.sendMsg({
                type: ProfileEventTypes.dataChanged,
                data: {
                  key: UserDataKeys.instName,
                  value: label
                }
              });

              onChange?.({ label, item } as Option);
              setRenderedErrorMessage('');
            }
          }}
          initItems={initItems}
          initValue={initValue}
          disabled={disabled}
          testId={TEST_ID.SELECT_INSTITUTION_PICKER}
          itemListMaxHeight={itemListMaxHeight}
          helperMessage={helperMessage}
          errorMessage={renderedErrorMessage}
        />
      )}

      {error && !alternateLoginFlow && (
        <Alert variant={AlertVariant.warning} testId={TEST_ID_ERROR}>
          We were unable to search for institutions, but you can continue and
          update your profile at a later time.
        </Alert>
      )}
    </div>
  );
};
