import React from 'react';
import { BoundaryProps, cleanProps, genPathTo } from './Boundary';
import { LandingProps, Landing } from '../steps/Landing';

export const path = '/landing';

export interface LandingBoundaryProps extends BoundaryProps, LandingProps {}

export const LandingBoundary: React.FC<LandingBoundaryProps> = props => {
  props = cleanProps(props);

  return <Landing {...props} />;
};

export interface SupportedPathOptions {}

export const pathTo = genPathTo<SupportedPathOptions>(path);

LandingBoundary.defaultProps = {
  path
};
