import { RouteComponentProps } from '@reach/router';
import { toQueryString } from '../../utility';

export interface BoundaryProps extends RouteComponentProps {}

export const genPathTo = <T extends any>(path: string) => {
  return (data: T) => {
    return path + toQueryString(data);
  };
};

export const cleanProps = <T extends BoundaryProps>(props: T) => {
  const rest = { ...props };
  delete rest.path;
  return rest;
};
