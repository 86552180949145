/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';

export interface BaseModalProps {
  toggler: (toShow: () => void) => React.ReactNode;
  content: (onHide: () => void) => React.ReactNode;
}

export const ToggleContent: React.FunctionComponent<BaseModalProps> = props => {
  const [visible, setVisible] = useState(false);
  const onHide = () => {
    setVisible(false);
  };
  const onShow = () => {
    setVisible(true);
  };

  return (
    <React.Fragment>
      {props.toggler(onShow)}
      {visible && props.content(onHide)}
    </React.Fragment>
  );
};
