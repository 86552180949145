import React from 'react';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import {
  loginHelper,
  loginLabel
} from '../../basic/LoginLabel';
import { isValidUserName, ValidatorResponse } from './Validator';

export const minLength = 1;
export const maxLength = 50;
export const TEST_ID = {
  MAIN: 'username-input'
};

export interface UsernameInputProps extends ValidatedDataInputProps {
  registerErrorMessage?: string
}

export const UsernameInput: React.FC<UsernameInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isValidUserName(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      {...props}
    />
  );
};

UsernameInput.defaultProps = {
  dataLabel: loginLabel,
  name: UserDataKeys.login,
  testId: TEST_ID.MAIN,
  id: 'username-input',
  required: true,
  helperMessage: `${maxLength} characters allowed`,
  whenValidated: () => {return;}
};
