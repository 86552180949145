import { UserDataKeys } from '../components/screens/Profile/UserDataKeys';
import { ThemeDataKeys } from '../components/screens/Profile/ThemeDataKeys';

interface FeatureFlags {
  showMerge?: boolean;
  newFacultyRegister?: boolean;
  showSchoolSearch?: boolean;
  useStandaloneProfileForm?: boolean;
  checkForDistrictLoginUser?: boolean;
}

export interface IDPLink {
  type: string;
  identifier: string;
  id: string;
  name: string;
  logo: string;
}

export interface IDPInfo {
  idpLinks: IDPLink[];
  activeIdp: IDPLink;
}

export interface RipsawInstitution {
  entityNumber: string;
  name: string;
  k12: boolean;
  cnow?: any;
  sheerOrg?: any;
  inCommon?: {
    entityId?: string;
    enabled?: boolean;
    logoUrl?: string;
  };
  source?: string;
  types?: any;
  deleted?: boolean;
  temp?: boolean;
  geography?: any;
}

interface Backend {
  relayState?: string;
  errors?: string;
  email?: string;
  uxmode?: string;
  parsedUXmode?: any;
  hidden?: any;
  values?: any;
  featureFlags?: FeatureFlags;
  idpInfo?: IDPInfo;
  errorCode?: string;
  correlationId?: string;
  serviceId?: string;
  uxModeInstitution?: RipsawInstitution;
  appLogoutUrlMap?: any;
  inIframe?: boolean;
  emailUrl?: string;
}

interface CustomWindow extends Window {
  backend: Backend;
}

declare let window: CustomWindow;
const keyMap: { [k: string]: string } = {
  institution: UserDataKeys.instId,
  institutionName: UserDataKeys.institutionName,
  timezone: UserDataKeys.timezone,
  theme: ThemeDataKeys.theme,
  loginId: UserDataKeys.loginId,
  termsAndConditions:UserDataKeys.termsAndConditions,
  marketingOptIn:UserDataKeys.marketingOptIn,
  skipEmailVerification: UserDataKeys.skipEmailVerification,
  autoVerifyUser: UserDataKeys.autoVerifyUser,
  userType: UserDataKeys.userType,
  birthYear: UserDataKeys.birthYear,
  firstName: UserDataKeys.firstName,
  lastName: UserDataKeys.lastName
};

const safeJsonParse = (input: string) => {
  let result = {};
  try {
    result = JSON.parse(input);
  } catch (e) {
    console.error('Failed to parse UX Mode:', e, input);
  }
  return result;
};

const ensureInstNameNotNull = (input: any) => {
  if (input[UserDataKeys.instId]) {
    input[UserDataKeys.instName] = '';
  }
};

export const parseUxMode = (win = window) => {
  let result: Backend = {};

  if (win) {
    result = win.backend || result;
  }
  if (result.uxmode) {
    result.parsedUXmode = safeJsonParse(result.uxmode);
    result.hidden = {};
    result.values = {};

    processParsedUXMode(result);
    ensureInstNameNotNull(result.values);
  }
  return result;
};

// @export-to-e2e start
export const ALFRED_COOKIES = {
  FF: {
    PRE: 'alfred-ff-cookie-',
    MERGE_VISIBLE: () => ALFRED_COOKIES.FF.PRE + 'merge-visible',
    INST_LOOKUP: () => ALFRED_COOKIES.FF.PRE + 'inst-lookup',
    NEW_FACULTY_REG: () => ALFRED_COOKIES.FF.PRE + 'new-faculty-reg',
    SCHOOL_SEARCH_VISIBLE: () =>
      ALFRED_COOKIES.FF.PRE + 'school-search-visible',
    STANDALONE_PROFILE_FORM: () =>
      ALFRED_COOKIES.FF.PRE + 'standalone-profile-form',

    setMergeVisible: (enabled: boolean) => {
      ALFRED_COOKIES.setCookie(ALFRED_COOKIES.FF.MERGE_VISIBLE(), enabled);
    },

    setNewFacultyRegister: (enabled: boolean) => {
      ALFRED_COOKIES.setCookie(ALFRED_COOKIES.FF.NEW_FACULTY_REG(), enabled);
    },

    setSchoolSearchVisible: (enabled: boolean) => {
      ALFRED_COOKIES.setCookie(
        ALFRED_COOKIES.FF.SCHOOL_SEARCH_VISIBLE(),
        enabled
      );
    },

    setStandaloneProfileForm: (enabled: boolean) => {
      ALFRED_COOKIES.setCookie(
        ALFRED_COOKIES.FF.STANDALONE_PROFILE_FORM(),
        enabled
      );
    },

    cookieOverride: <T>(
      key: string,
      orElse: T,
      conv: (v: string) => T = JSON.parse
    ): T => {
      // Parse cookie data
      const cookieData = document.cookie
        .split(';')
        .map(v => v.trimLeft())
        .filter(v => v.startsWith(ALFRED_COOKIES.FF.PRE))
        .reduce((result, value) => {
          const [k, v] = value.split('=');
          result[k] = v;
          return result;
        }, {} as Record<string, string>);

      // Try to convert the value, otherwise return default
      const cookieValue = cookieData[key];
      if (cookieValue) {
        return conv(cookieValue);
      }
      return orElse;
    }
  },

  setCookie: <T>(
    key: string,
    value: T,
    conv: (v: T) => string = JSON.stringify
  ) => {
    document.cookie = `${key}=${conv(value)};path=/`;
  },

  clearCookie: (key: string) => {
    document.cookie = `${key}=;path=/;max-age=0`;
  }
};
// @export-to-e2e stop

export const featureFlags = {
  isMergeVisible: () => {
    return ALFRED_COOKIES.FF.cookieOverride(
      ALFRED_COOKIES.FF.MERGE_VISIBLE(),
      window.backend?.featureFlags?.showMerge ?? false
    );
  },

  isNewFacultyRegister: () => {
    return ALFRED_COOKIES.FF.cookieOverride(
      ALFRED_COOKIES.FF.NEW_FACULTY_REG(),
      window.backend?.featureFlags?.newFacultyRegister ?? false
    );
  },

  isSchoolSearchVisible: () => {
    return ALFRED_COOKIES.FF.cookieOverride(
      ALFRED_COOKIES.FF.SCHOOL_SEARCH_VISIBLE(),
      window.backend?.featureFlags?.showSchoolSearch ?? false
    );
  },

  isUseStandaloneProfileForm: () => {
    return ALFRED_COOKIES.FF.cookieOverride(
      ALFRED_COOKIES.FF.STANDALONE_PROFILE_FORM(),
      window.backend?.featureFlags?.useStandaloneProfileForm ?? false
    );
  },

  isCheckForDistrictLoginUsers: () => {
    return window.backend?.featureFlags?.checkForDistrictLoginUser;
  }
};

function processParsedUXMode(result: Backend) {
  for (const key of Object.keys(result.parsedUXmode)) {
    const newKey: string = keyMap[key];

    if (undefined === newKey) {
      throw new Error('Invalid UXMode attribute');
    }
    if (newKey === ThemeDataKeys.theme) {
      // Placeholder since we'll probably want to do something here...
    } else {
      const data = result.parsedUXmode[key];
      if (data.hidden || undefined === data.hidden) {
        result.hidden[newKey] = true;
      }
      if (data.value) {
        result.values[newKey] = data.value;
      }
    }
  }
}