import React, { useMemo, useState, useLayoutEffect } from 'react';
import { Combobox as MComboBox } from 'react-magma-dom';
import { Option } from './shared';
import { SearchIcon } from 'react-magma-icons';
import { ComboboxProps } from 'react-magma-dom/dist/components/Combobox';

/**
 * Generates a testId for the loading element based on the parent testId.
 * @param testId The parent testId to use when constructing the testId
 * @returns A new testId based on the parent testId or undefined if undefined
 */
export const loadingTestId = (testId?: string) => {
  if (undefined === testId) {
    return undefined;
  }
  return `${testId}__loading`;
};

const genDebouncer = (
  callback: (value: string) => Promise<Option[]>,
  debounceMs?: number
) => {
  if (undefined === debounceMs) {
    return callback;
  }

  let timerId: any;
  return async (value: string) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    await new Promise(res => {
      timerId = setTimeout(res, debounceMs);
    });
    timerId = undefined;
    return callback(value);
  };
};

export interface AsyncSelectProps
  extends Omit<ComboboxProps<Option>, 'selectedItem' | 'items'> {
  // The number of milliseconds to wait before firing off a request
  debounceMS?: number;

  loadOptions?: (value: string) => Promise<Option[]>;
  filterOptions?: (inputValue: string, option: Option) => boolean;

  initItems?: Promise<Option[]>;
  initValue?: Promise<Option | undefined>;
}

export const AsyncSelect: React.FC<AsyncSelectProps> = props => {
  const {
    debounceMS,
    loadOptions,
    filterOptions,

    components,
    isLoading,

    initItems,
    initValue,

    onSelectedItemChange: propOnSelectedItemChange,
    onInputKeyDown: propOnInputKeyDown,

    ...rest
  } = props;

  const debouncer = useMemo(
    () =>
      genDebouncer(async value => {
        if (loadOptions) {
          return loadOptions(value);
        }
        return (await initItems) ?? [];
      }, debounceMS),
    [debounceMS, loadOptions, initItems]
  );

  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([] as Option[]);
  const [searchItems, setSearchItems] = useState(
    undefined as undefined | Option[]
  );
  const [selectedItem, setSelectedItem] = useState(
    undefined as undefined | Option
  );

  const toLoad = useMemo(() => {
    initItems?.then(v => v && setItems(v));
    initValue?.then(v => setSelectedItem(v));
    return [initItems, initValue].filter(v => v) as Promise<any>[];
  }, [initItems, initValue]);

  const [initLoading, setInitLoading] = useState(0 !== toLoad.length);
  useLayoutEffect(() => {
    Promise.all(toLoad).finally(() => {
      setInitLoading(false);
    });
  }, [toLoad]);

  if (initLoading) {
    // Only if there are init values to load
    const loadItem: Option = { label: 'Loading...', item: '' };
    // For some reason, matching the types of the loading element
    // and the final element causes a weird side-effect where
    // react doesn't update and swap the elements. Make sure that this
    // loading element doesn't match the final element in type.
    return (
      <React.Fragment>
        <MComboBox
          testId={loadingTestId(rest.testId)}
          labelText={rest.labelText}
          defaultItems={[loadItem]}
          selectedItem={loadItem}
          disabled
          isLoading
        />
      </React.Fragment>
    );
  }

  return (
    <div>
      <MComboBox
        {...rest}
        itemToString={(...args) => {
          // For some reason, we have to provide the label, even though we're matching
          // the expected shape of items... Not sure why, but not willing to dig in
          return args?.[0]?.label;
        }}
        onInputKeyDown={e => {
          if ('Enter' === e.key) {
            // Put in place to avoid accidentally activating other things
            // that listen for the enter press. This was recommended by
            // the Magma team since they don't see themselves swallowing
            // the event. We don't return here because we'd rather expose
            // the event to other listeners, in case they need it.
            e.stopPropagation();
            e.preventDefault();
          }
          propOnInputKeyDown?.(e);
        }}
        onSelectedItemChange={changes => {
          // Done searching
          if (changes.selectedItem) {
            setSearchItems(undefined);
            setSelectedItem(changes.selectedItem);
          }
          propOnSelectedItemChange?.(changes);
        }}
        onInputValueChange={async changes => {
          const { inputValue, isOpen } = changes;
          // Making sure to fire this only when the menu is open and
          // the input value exists is important to avoid side-effects
          if (isOpen && undefined !== inputValue) {
            try {
              setLoading(true);
              const result = await debouncer(inputValue).then(v => {
                if (filterOptions) {
                  v = v.filter(w => filterOptions(inputValue, w));
                }
                return v;
              });
              setSearchItems(result);
            } finally {
              setLoading(false);
            }
          }
        }}
        isLoading={loading || isLoading}
        selectedItem={selectedItem}
        items={searchItems ?? items}
        // Provided to ensure that we'll be able to show our selectedItem
        // Makes sure to include selectedItem and any loaded items
        defaultItems={
          [selectedItem, ...items].filter(
            (v, i, arr) => v && i === arr.indexOf(v)
          ) as Option[]
        }
        components={{
          DropdownIndicator: () => <SearchIcon />,
          ...components
        }}
        disableCreateItem
      />
      <input
        // This input is used for testing purposes (mostly required for e2e, but used in unit as well)
        aria-hidden={true}
        data-testid={rest.testId ? `${rest.testId}__hidden_value` : undefined}
        type="hidden"
        value={selectedItem?.item ?? ''}
      />
    </div>
  );
};
