import React from 'react';
import { BoundaryProps, cleanProps, genPathTo } from './Boundary';
import {
  FacultyEmailSent,
  FacultyEmailSentProps
} from '../steps/FacultyEmailSent';
import { useQueryParams } from '../hooks/useQueryParams';

export const path = '/email-sent';
const paramUsername = 'username';
const paramVerifyId = 'verifyId';
const paramVerifyStatus = 'verifyStatus';

export interface FacultyEmailSentBoundaryProps
  extends BoundaryProps,
    Omit<FacultyEmailSentProps, 'emailAddress'> {}

export const FacultyEmailSentBoundary: React.FC<FacultyEmailSentBoundaryProps> = props => {
  props = cleanProps(props);

  const params = useQueryParams();

  // Note: username is used because we are transitioning to
  // username throughout the codebase
  const [username] = params[paramUsername];
  const [verifyId] = params[paramVerifyId];
  const [verifyStatus] = params[paramVerifyStatus];

  return (
    <FacultyEmailSent
      emailAddress={username}
      verifyId={verifyId}
      verifyStatus={verifyStatus}
      {...props}
    />
  );
};

export interface SupportedPathOptions {
  [paramUsername]: string;
  [paramVerifyId]?: string;
  [paramVerifyStatus]?: string;
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

FacultyEmailSentBoundary.defaultProps = {
  path
};
