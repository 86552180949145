import * as querystring from 'querystring';
import { WindowLocation } from '@reach/router';

// This is used to trim off any question marks at the front of the query string
const REG_TRIM_QUEST = /^\?/;

/**
 * Converts a given set of data into a querystring
 * @param data Data to be converted into a querystring
 */
export const toQueryString = (data: querystring.ParsedUrlQueryInput | any) => {
  const joinedData = {
    ...fromLocQueryString(window?.location),
    ...data
  };
  const result = querystring.stringify(joinedData);
  if (!result) {
    return '';
  }
  return '?' + result;
};

/**
 * Converts a querystring into a usable object
 * @param data Querystring to convert into a usable object
 */
export const fromQueryString = (data: string) => {
  return querystring.parse(data.replace(REG_TRIM_QUEST, ''));
};

/**
 * A helper method that makes it easier to parse out the current WindowLocation
 * @param location WindowLocation to use for parsing the queryString
 */
export const fromLocQueryString = (location?: WindowLocation | Location) => {
  let search = '';
  if (location && location.search) {
    search = location.search;
  }
  return fromQueryString(search);
};
/**
 * Stores an item into local storage if value is provided.
 * If value is null then removes that entry for the given key from local storage.
 * @param key The key to store item to local storage
 * @param value The value to be stored in local storage.
 */
export const setLocalStorageItem = (key: string, value: string | null): void => {
  if (value) {
    window.localStorage.setItem(key, value);
  } else {
    window.localStorage.removeItem(key);
  }
};

/**
 * Returns JSON object or string if item found in the local storage otherwise null.
 * @param key The key to retrieve item from local storage
 */
export const getLocalStorageJsonItem = (key: string): any => {
  const value = window.localStorage.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (ex) {
      //Ignore exception
      console.log('Can not parse as JSON object. '+value);
    }
  }
  return null;
};