import { UpCommMethodParams, useUpComm } from '../../basic/UpComm';

export interface OverrideData {
  hidden: { [k: string]: boolean };
  values: { [k: string]: string };
}

export interface ProfileCtx {
  isEditing: boolean;
  isValid: boolean;
  overrides?: OverrideData;
}

/**
 * This is the hook that would be used if someone wants access to the
 * UpCommContext. It provides type safety against ProfileCtx.
 */
export const useProfileUpComm = () => {
  return useUpComm({} as ProfileCtx);
};

export interface ProfileUpComm {
  // This is the mechanism that allows for data to be
  // passed upwards easily. Shared state and communication
  upComm?: UpCommMethodParams<ProfileCtx>;
}

export interface ProfileUpCommRequired extends ProfileUpComm {
  // This is the mechanism that allows for data to be
  // passed upwards easily. Shared state and communication
  upComm: UpCommMethodParams<ProfileCtx>;
}

export enum ProfileEventTypes {
  // Sent when data has a default value and thus might not be changed,
  // does not set editing mode.
  dataInit = 'dataInitialized',
  // Sent when data is updated
  dataChanged = 'dataChanged',
  // Sent when data is attempting to be saved
  dataSaved = 'dataSaved',
  // Sent when an invalid value has been found for some data element
  dataInvalid = 'dataInvalid',
  // Sent when a data element is updating whether or not it is required
  dataRequired = 'dataRequired',
  // Sent when the current value in username should be discovered
  discoverUsername = 'discoverUsername',
  // Events that are only triggered by using the appropriate NavigateListener
  pageLoad = 'pageLoad',
  pageUnload = 'pageUnload'
}
