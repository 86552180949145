import React from 'react';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { isPhoneValid, ValidatorResponse } from './Validator';

const digitLength = 10;
export const TEST_ID = {
  MAIN: 'phone-number-input'
};

export interface PhoneNumberInputProps extends ValidatedDataInputProps {}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isPhoneValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      {...props}
    />
  );
};

PhoneNumberInput.defaultProps = {
  dataLabel: 'Phone Number',
  name: UserDataKeys.phoneNumber,
  testId: TEST_ID.MAIN,
  id: 'phone-number-input',
  required: false,
  helperMessage: `${digitLength} digit phone number`,
  whenValidated: () => { return; }
};
