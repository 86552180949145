import React from 'react';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import { FacultyDataKeys } from '../../screens/Profile/FacultyDataKeys';
import { isInstitutionNameValid, ValidatorResponse } from './Validator';

export const TEST_ID = { MAIN: 'input-institution-name' };
export const minLength = 2;
export const maxLength = 50;

export interface InstitutionNameInputProps extends ValidatedDataInputProps {}

export const InstitutionNameInput: React.FC<InstitutionNameInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isInstitutionNameValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      {...props}
    />
  );
};

InstitutionNameInput.defaultProps = {
  dataLabel: 'Institution Name',
  name: FacultyDataKeys.entityName,
  testId: TEST_ID.MAIN,
  id: 'institution-name-input',
  required: true,
  helperMessage: `${maxLength} characters allowed`,
  whenValidated: () => {return;}
};
