/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { isValidStandardInput, ValidatorResponse } from '../../components/data-inputs/validated/Validator';
import { DataInput } from '../../components/screens/Profile/data-input/DataInput';
import { Button } from '../../components/magma-wrap/Button';
import { Paragraph } from 'react-magma-dom';

export const TEST_ID = {
  ACTIVATE_BUTTON: 'button-activate',
  ACTIVATE_INPUT: 'input-activate'
};

export interface ResendPasswordResetBtnProps {
  username: string;
}

export const RecoveryTokenForm: React.FC<ResendPasswordResetBtnProps> = props => {
  const username = props.username;
  const [isValidToken, setIsValidToken] = useState(false);
  const [tokenValue, setTokenValue] = useState('');
  return (
    <React.Fragment>
      <Paragraph className='okta-form-subtitle o-form-explain'>
        If you are a k-12 student, the password reset email was sent to your teacher or administrator.</Paragraph>
      <DataInput
        validator={async (value: string) => {
          const isValid: ValidatorResponse = isValidStandardInput(value);
          if (!isValid.response) {
            setIsValidToken(false);
            throw new Error(isValid.reason);
          } else {
            setTokenValue(value);
            setIsValidToken(true);
          }
        }}
        dataLabel={'Recovery code'}
        id={'recoveryToken'}
        testId={TEST_ID.ACTIVATE_INPUT}
      />
      <Button
        onClick={() => {
          (document.location as any) = '/reset_password/' + tokenValue +
              '/' + username;
        }}
        value="SUBMIT"
        block
        testId={TEST_ID.ACTIVATE_BUTTON}
        disabled={!isValidToken}
      />
    </React.Fragment>
  );
};