import React from 'react';
import { UserDataKeys } from '../../screens/Profile/UserDataKeys';
import { DataInput } from '../../screens/Profile/data-input/DataInput';
import { ValidatedDataInputProps } from './ValidatedDataInput';
import {
  loginLabel,
  loginHelper, emailLoginLabel
} from '../../basic/LoginLabel';
import { isAlternateLoginValid, ValidatorResponse } from './Validator';

export const TEST_ID = {
  MAIN: 'alternate-login-input'
};
export const minLength = 6;
export const maxLength = 100;

export interface AltLoginInputProps extends ValidatedDataInputProps {
  persistEvent?: boolean
}

export const AltLoginInput: React.FC<AltLoginInputProps> = props => {
  const { whenValidated } = props;
  return (
    <DataInput
      validator={async (value: string) => {
        const isValid: ValidatorResponse = isAlternateLoginValid(value);
        if (!isValid.response) {
          whenValidated?.(false);
          throw new Error(isValid.reason);
        }
        whenValidated?.(true);
      }}
      // Mark this as a guaranteed value (defaultProp) so that TS is happy
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dataLabel={props.dataLabel!}
      required={false}
      {...props}
    />
  );
};

AltLoginInput.defaultProps = {
  dataLabel: 'Alternate ' + emailLoginLabel,
  name: UserDataKeys.alternateLogin,
  testId: TEST_ID.MAIN,
  id: 'alternate-login-input',
  required: false,
  helperMessage: `${minLength}-${maxLength} chars, ${loginHelper.toLowerCase()}`,
  persistEvent: false,
  whenValidated: () => {return;}
};
