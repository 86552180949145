import React from 'react';
import { BoundaryProps, cleanProps, genPathTo } from './Boundary';
import { EmailSent, EmailSentProps } from '../EmailSent';
import { useQueryParams } from '../hooks/useQueryParams';

export const path = '/email-sent';
const paramUsername = 'username';

export interface EmailSentBoundaryProps
  extends BoundaryProps,
    Omit<EmailSentProps, 'emailAddress'> {}

export const EmailSentBoundary: React.FC<EmailSentBoundaryProps> = props => {
  props = cleanProps(props);

  const params = useQueryParams();

  // Note: username is used because we are transitioning to
  // username throughout the codebase
  const [username] = params[paramUsername];

  return <EmailSent emailAddress={username} {...props} />;
};

export interface SupportedPathOptions {
  [paramUsername]: string;
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

EmailSentBoundary.defaultProps = {
  path
};
