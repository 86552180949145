import React, { useState } from 'react';
import { Input, InputType, ButtonColor, SpacerAxis, magma, Spacer } from 'react-magma-dom';
import { ActionButton } from '../../../../basic/ActionButton';
import { verifyFactor, enrollFactor } from '../../../../../api';
import { trackingClient } from '../../../../../ext/tracking';

export interface VerifyCodeProps {
  email: string;
  onVerified?: () => void;
  onFailure?: (err: Error) => void;
}

export const VerifyCode: React.FC<VerifyCodeProps> = props => {
  const [verCode, setVerCode] = useState('');

  const storeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerCode(event.target.value);
  };

  return (
    <>
      <Input
        type={InputType.text}
        labelText="Verification Code"
        onBlur={() => {
          trackingClient.event.verifyAlternateEmail.changeVerificationCode({
            tags: { name: 'verificationCode' }
          });
        }}
        onChange={storeCode}
      />
      <ActionButton
        onActionChildren="Verifying"
        onAction={() => {
          trackingClient.event.verifyAlternateEmail.verifyButtonClick();
          return verifyFactor(verCode);
        }}
        onSuccess={props.onVerified}
        onFailure={props.onFailure}
      >
        Verify
      </ActionButton>
      <Spacer axis={SpacerAxis.horizontal} size={magma.spaceScale.spacing03}/>
      <ActionButton
        color={ButtonColor.secondary}
        toastOnSuccess="Code sent!"
        toastOnFailure="Code could not be sent"
        onActionChildren="Resending Verification Code"
        onAction={() => {
          trackingClient.event.verifyAlternateEmail.resendValidationCodeClick();
          return enrollFactor(props.email);
        }}
      >
        Resend Verification Code
      </ActionButton>
    </>
  );
};
