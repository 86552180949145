// These variables can be referenced "safely" from a context where
// we don't expect dev-help might not exist. This is useful for
// adding commands safely, etc.
//
// As a result, this should not import anything unless it is a lightweight
// import (since the constants here are likely to be thrown away).
//
// It is also safe to put types here, as they will be removed on compilation.

export const DEV_HELP_GLOBAL = 'dev-help';

export interface ActionResult {
  showMessage?: string;
}

export interface CommandInfo {
  id: string;
  display: string;
  action: () => ActionResult | void;
}

export interface CommandInfoMap {
  [k: string]: CommandInfo;
}

export interface CommandInfoMapCB {
  (commands: CommandInfoMap): void;
}

declare global {
  interface Window {
    [DEV_HELP_GLOBAL]: {
      commands: CommandInfoMap;
      useCommand: (info: CommandInfo) => void;
      useForCommands: (cb: (commands: CommandInfoMap) => void) => void;
    };
  }
}
