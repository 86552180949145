/******************************************************
 * This file was created by scripts/generate.js as part
 * of the build process. Do not edit this file directly.
 ******************************************************/

import { IconProps } from "../../IconProps";
import { renderIcon } from "../../SvgIcon";
const iconType = {
  viewBox: "0 0 24 24",
  paths: [
    {
      d: "M6.9191 2.2722a.9292.9292 0 011.3142 0l4.6469 4.647a.9292.9292 0 010 1.314.9249.9249 0 01-.6571.2724.9268.9268 0 01-.657-.2723L8.5055 5.1728v15.6749a.9291.9291 0 01-.9294.9294.9291.9291 0 01-.9294-.9294V5.1728L3.5863 8.2333a.9292.9292 0 01-1.3141 0 .9292.9292 0 010-1.3142zM16.4238 2a.9291.9291 0 01.9294.9294v15.6749l3.0605-3.0605a.9268.9268 0 01.657-.2723c.238 0 .4759.0906.6571.2723a.9292.9292 0 010 1.3142l-4.647 4.6469a.9292.9292 0 01-1.314 0l-4.647-4.647a.9292.9292 0 010-1.314.9292.9292 0 011.3142 0l3.0604 3.0604V2.9293A.9291.9291 0 0116.4238 2z",
      fillRule: "evenodd",
    },
  ],
  circles: [],
};

export const SortDoubleArrowIcon = (props: IconProps) =>
  renderIcon(props, iconType);
