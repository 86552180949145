// ===========================================================================================================
// GENERATED FILE -- Do not modify manually.
//
// Any changes should be made within <project>/scripts/gen-tracking-data/index.ts
// To add new actions/categories, use <project>/scripts/gen-tracking-data/tracking-actions
// ===========================================================================================================

export const ACTIONS = {
  signIn: {
    loadViewPage: {
      category: 'Sign-In',
      action: 'Load-View-Page'
    },
    loginButtonClick: {
      category: 'Sign-In',
      action: 'Login-Button-Click'
    },
    helpLinkClick: {
      category: 'Sign-In',
      action: 'Help-Link-Click'
    },
    createNewStudentAccountClick: {
      category: 'Sign-In',
      action: 'Create-New-Student-Account-Click'
    },
    createNewInstructorAccountClick: {
      category: 'Sign-In',
      action: 'Create-New-Instructor-Account-Click'
    },
    needHelpSigningInClick: {
      category: 'Sign-In',
      action: 'Need-Help-Signing-In-Click'
    },
    failedLogin: {
      category: 'Sign-In',
      action: 'Failed-Login'
    },
    forgotPasswordClick: {
      category: 'Sign-In',
      action: 'Forgot-Password-Click'
    },
    unloadViewPage: {
      category: 'Sign-In',
      action: 'Unload-View-Page'
    }
  },
  discovery: {
    loadViewPage: {
      category: 'Discovery',
      action: 'Load-View-Page'
    },
    nextButtonClick: {
      category: 'Discovery',
      action: 'Next-Button-Click'
    },
    returningUserClick: {
      category: 'Discovery',
      action: 'Returning-User-Click'
    },
    unloadViewPage: {
      category: 'Discovery',
      action: 'Unload-View-Page'
    }
  },
  facultyDiscovery: {
    loadViewPage: {
      category: 'Faculty-Discovery',
      action: 'Load-View-Page'
    },
    nextButtonClick: {
      category: 'Faculty-Discovery',
      action: 'Next-Button-Click'
    },
    returningUserClick: {
      category: 'Faculty-Discovery',
      action: 'Returning-User-Click'
    },
    unloadViewPage: {
      category: 'Faculty-Discovery',
      action: 'Unload-View-Page'
    }
  },
  registrationDetails: {
    loadViewPage: {
      category: 'Registration-Details',
      action: 'Load-View-Page'
    },
    termsOfUseClick: {
      category: 'Registration-Details',
      action: 'Terms-Of-Use-Click'
    },
    privacyPolicyClick: {
      category: 'Registration-Details',
      action: 'Privacy-Policy-Click'
    },
    institutionSelectionStart: {
      category: 'Registration-Details',
      action: 'Institution-Selection-Start'
    },
    timezoneSelectionStart: {
      category: 'Registration-Details',
      action: 'Timezone-Selection-Start'
    },
    nextButtonClick: {
      category: 'Registration-Details',
      action: 'Next-Button-Click'
    },
    validationError: {
      category: 'Registration-Details',
      action: 'Validation-Error'
    },
    unloadViewPage: {
      category: 'Registration-Details',
      action: 'Unload-View-Page'
    }
  },
  facultyRegistrationDetails: {
    loadViewPage: {
      category: 'Faculty-Registration-Details',
      action: 'Load-View-Page'
    },
    termsOfUseClick: {
      category: 'Faculty-Registration-Details',
      action: 'Terms-Of-Use-Click'
    },
    privacyPolicyClick: {
      category: 'Faculty-Registration-Details',
      action: 'Privacy-Policy-Click'
    },
    institutionSelectionStart: {
      category: 'Faculty-Registration-Details',
      action: 'Institution-Selection-Start'
    },
    institutionNotFoundClick: {
      category: 'Faculty-Registration-Details',
      action: 'Institution-Not-Found-Click'
    },
    timezoneSelectionStart: {
      category: 'Faculty-Registration-Details',
      action: 'Timezone-Selection-Start'
    },
    nextButtonClick: {
      category: 'Faculty-Registration-Details',
      action: 'Next-Button-Click'
    },
    validationError: {
      category: 'Faculty-Registration-Details',
      action: 'Validation-Error'
    },
    manuallyValidateClick: {
      category: 'Faculty-Registration-Details',
      action: 'Manually-Validate-Click'
    },
    unloadViewPage: {
      category: 'Faculty-Registration-Details',
      action: 'Unload-View-Page'
    }
  },
  registrationActivationSent: {
    loadViewPage: {
      category: 'Registration-Activation-Sent',
      action: 'Load-View-Page'
    },
    resendEmailClick: {
      category: 'Registration-Activation-Sent',
      action: 'Resend-Email-Click'
    },
    signInClick: {
      category: 'Registration-Activation-Sent',
      action: 'Sign-In-Click'
    },
    unloadViewPage: {
      category: 'Registration-Activation-Sent',
      action: 'Unload-View-Page'
    }
  },
  facultyRegistrationActivationSent: {
    loadViewPage: {
      category: 'Faculty-Registration-Activation-Sent',
      action: 'Load-View-Page'
    },
    resendEmailClick: {
      category: 'Faculty-Registration-Activation-Sent',
      action: 'Resend-Email-Click'
    },
    manualVerificationLinkClick: {
      category: 'Faculty-Registration-Activation-Sent',
      action: 'Manual-Verification-Link-Click'
    },
    signInClick: {
      category: 'Faculty-Registration-Activation-Sent',
      action: 'Sign-In-Click'
    },
    unloadViewPage: {
      category: 'Faculty-Registration-Activation-Sent',
      action: 'Unload-View-Page'
    }
  },
  activateWithPassword: {
    loadViewPage: {
      category: 'Activate-With-Password',
      action: 'Load-View-Page'
    },
    savePasswordClick: {
      category: 'Activate-With-Password',
      action: 'Save-Password-Click'
    },
    unloadViewPage: {
      category: 'Activate-With-Password',
      action: 'Unload-View-Page'
    }
  },
  completed: {
    loadViewPage: {
      category: 'Completed',
      action: 'Load-View-Page'
    },
    redirectClick: {
      category: 'Completed',
      action: 'Redirect-Click'
    },
    unloadViewPage: {
      category: 'Completed',
      action: 'Unload-View-Page'
    }
  },
  resetPasswordStart: {
    loadViewPage: {
      category: 'Reset-Password-Start',
      action: 'Load-View-Page'
    },
    sendPasswordResetClick: {
      category: 'Reset-Password-Start',
      action: 'Send-Password-Reset-Click'
    },
    returnToSignInClick: {
      category: 'Reset-Password-Start',
      action: 'Return-To-Sign-In-Click'
    },
    unloadViewPage: {
      category: 'Reset-Password-Start',
      action: 'Unload-View-Page'
    }
  },
  resetPassword: {
    loadViewPage: {
      category: 'Reset-Password',
      action: 'Load-View-Page'
    },
    resetPasswordClick: {
      category: 'Reset-Password',
      action: 'Reset-Password-Click'
    },
    returnToSignInClick: {
      category: 'Reset-Password',
      action: 'Return-To-Sign-In-Click'
    },
    unloadViewPage: {
      category: 'Reset-Password',
      action: 'Unload-View-Page'
    }
  },
  profileEdit: {
    loadViewPage: {
      category: 'Profile-Edit',
      action: 'Load-View-Page'
    },
    changePasswordClick: {
      category: 'Profile-Edit',
      action: 'Change-Password-Click'
    },
    saveClick: {
      category: 'Profile-Edit',
      action: 'Save-Click'
    },
    makeAlternateEmailUsernameClick: {
      category: 'Profile-Edit',
      action: 'Make-Alternate-Email-Username-Click'
    },
    changeData: {
      category: 'Profile-Edit',
      action: 'Change-Data'
    },
    editStarted: {
      category: 'Profile-Edit',
      action: 'Edit-Started'
    },
    mouseOverChangeUsername: {
      category: 'Profile-Edit',
      action: 'Mouse-Over-Change-Username'
    },
    unloadViewPage: {
      category: 'Profile-Edit',
      action: 'Unload-View-Page'
    }
  },
  profileEditPassword: {
    loadViewPage: {
      category: 'Profile-Edit-Password',
      action: 'Load-View-Page'
    },
    cancelClick: {
      category: 'Profile-Edit-Password',
      action: 'Cancel-Click'
    },
    saveClick: {
      category: 'Profile-Edit-Password',
      action: 'Save-Click'
    },
    unloadViewPage: {
      category: 'Profile-Edit-Password',
      action: 'Unload-View-Page'
    }
  },
  profileEditAlternateEmail: {
    loadViewPage: {
      category: 'Profile-Edit-Alternate-Email',
      action: 'Load-View-Page'
    },
    cancelClick: {
      category: 'Profile-Edit-Alternate-Email',
      action: 'Cancel-Click'
    },
    saveClick: {
      category: 'Profile-Edit-Alternate-Email',
      action: 'Save-Click'
    },
    unloadViewPage: {
      category: 'Profile-Edit-Alternate-Email',
      action: 'Unload-View-Page'
    }
  },
  verifyAlternateEmail: {
    loadViewPage: {
      category: 'Verify-Alternate-Email',
      action: 'Load-View-Page'
    },
    resendValidationCodeClick: {
      category: 'Verify-Alternate-Email',
      action: 'Resend-Validation-Code-Click'
    },
    verifyButtonClick: {
      category: 'Verify-Alternate-Email',
      action: 'Verify-Button-Click'
    },
    changeVerificationCode: {
      category: 'Verify-Alternate-Email',
      action: 'Change-Verification-Code'
    },
    unloadViewPage: {
      category: 'Verify-Alternate-Email',
      action: 'Unload-View-Page'
    }
  },
  changeUsernameSave: {
    loadViewPage: {
      category: 'Change-Username-Save',
      action: 'Load-View-Page'
    },
    changeUsernameClick: {
      category: 'Change-Username-Save',
      action: 'Change-Username-Click'
    },
    unloadViewPage: {
      category: 'Change-Username-Save',
      action: 'Unload-View-Page'
    }
  }
};

export type ActionConstMap = typeof ACTIONS;
export type Category = keyof ActionConstMap;

// Freeze all of the ids so that they can't be changed
for (const category of Object.keys(ACTIONS) as Category[]) {
  const categoryMap: any = ACTIONS[category];
  for (const action of Object.keys(categoryMap)) {
    Object.freeze(categoryMap[action]);
  }
  Object.freeze(categoryMap);
}
Object.freeze(ACTIONS);
