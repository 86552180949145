import React from 'react';
import { Button as MButton } from 'react-magma-dom';
import { ButtonProps as MButtonProps } from 'react-magma-dom/dist/components/Button';

export interface ButtonProps extends MButtonProps {
  value?: string;

  // Strictly for backwards compatibility; should use isFullWidth going forward
  block?: MButtonProps['isFullWidth'];
}

export const Button = React.forwardRef<HTMLButtonElement | null, ButtonProps>(
  function Button(props, ref) {
    const { children, value, block, ...rest } = props;
    rest['isFullWidth'] = rest['isFullWidth'] ?? block;
    return (
      <MButton ref={ref} {...rest} style={{ margin:'15 0 15 0', fontWeight: 400 }}>
        {children ?? value}
      </MButton>
    );
  }
);
