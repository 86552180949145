import React, { useState } from 'react';
import {
  Modal,
  Heading
} from 'react-magma-dom';
import { LoginValidation } from '../../../basic/LoginValidation';
import { DEV_HELP_GLOBAL } from '../../../../dev/dev-barrier/safe-ref';
import { mergeLogins, MergeDataPass } from '../../../../api';
import { MergeAccounts } from './MergeAccounts';
import { ChooseAccounts } from './ChooseAccounts';

const testId = (v: string) => `merge-logins-${v}`;
export const TEST_ID = {
  PRIMARY_LOGIN: testId('primary-login'),
  SECONDARY_LOGIN: testId('secondary-login'),
  MERGE_DESCRIPTION: testId('description'),
  ACCOUNT_CHOOSE: testId('account-choose'),
  AGREEMENT_DESCRIPTION: testId('agree-desc'),
  MERGE_BUTTON: testId('merge-button'),
  MERGING_ICON: testId('merging-icon'),
  SUCCESS: testId('success-message'),
  FAILURE: testId('failure-message')
};

export enum MergeState {
  init = 'init',
  unknown = 'unknown',
  success = 'success',
  merging = 'merging',
  failure = 'failure'
}

interface MergeLoginsModalTestProps {
  mergeState?: MergeState;
  primaryPass?: string;
  secondPass?: string;
  chosenLogin?: string;
}

export interface MergeLoginsModalProps {
  primaryLogin: string;
  secondLogin: string;
  onClose: (state: MergeState) => void;
  open: boolean;

  _testProps?: MergeLoginsModalTestProps;
}

export interface MergeDataSource {
  chosenLogin: string;
  primaryLogin: string;
  primaryPass: string;
  secondLogin: string;
  secondPass: string;
}

export const buildMergeRequest = ({
  chosenLogin,
  primaryLogin,
  primaryPass,
  secondLogin,
  secondPass
}: MergeDataSource): MergeDataPass => {
  if (chosenLogin === primaryLogin) {
    return {
      sourceLogin: secondLogin,
      sourcePassword: secondPass,
      targetLogin: primaryLogin,
      targetPassword: primaryPass
    };
  } else if (chosenLogin === secondLogin) {
    return {
      targetLogin: secondLogin,
      targetPassword: secondPass,
      sourceLogin: primaryLogin,
      sourcePassword: primaryPass
    };
  } else {
    throw new Error(
      'Unexpected values: expected chosen to match primary or secondary'
    );
  }
};

export const MergeLoginsModal: React.FC<MergeLoginsModalProps> = props => {
  const { primaryLogin, secondLogin, open, onClose, _testProps } = props;

  const [_apiMergeLogins, _setApiMergeLogins] = useState(() => mergeLogins);
  /* istanbul ignore next */
  window?.[DEV_HELP_GLOBAL]?.useCommand?.({
    id: 'profile.merge.validPasses',
    display: 'Fake valid passes',
    action: () => {
      setPrimaryPass('fake');
      setSecondPass('fake');
    }
  });
  /* istanbul ignore next */
  window?.[DEV_HELP_GLOBAL]?.useCommand?.({
    id: 'profile.merge.success',
    display: 'Merge success',
    action: () => {
      _setApiMergeLogins(() => async () => {
        return true;
      });
    }
  });
  /* istanbul ignore next */
  window?.[DEV_HELP_GLOBAL]?.useCommand?.({
    id: 'profile.merge.successSlow',
    display: 'Merge success slow',
    action: () => {
      _setApiMergeLogins(() => () => {
        return new Promise(res => {
          setTimeout(() => {
            res(true);
          }, 3000);
        });
      });
    }
  });
  /* istanbul ignore next */
  window?.[DEV_HELP_GLOBAL]?.useCommand?.({
    id: 'profile.merge.failure',
    display: 'Merge failure',
    action: () => {
      _setApiMergeLogins(() => async () => {
        throw new Error('fake error');
      });
    }
  });

  const [mergeState, setMergeState] = useState(
    _testProps?.mergeState ?? MergeState.init
  );

  const [primaryPass, setPrimaryPass] = useState(
    _testProps?.primaryPass ?? (null as string | null)
  );
  const [secondPass, setSecondPass] = useState(
    _testProps?.secondPass ?? (null as string | null)
  );

  const [chosenLogin, setChosenLogin] = useState(
    _testProps?.chosenLogin ?? (null as string | null)
  );

  return (
    <Modal isOpen={open} onClose={onClose?.bind?.(null, mergeState)}>
      <Heading level={2}>Merge Accounts</Heading>

      <LoginValidation
        labelPrefix="Primary"
        login={primaryLogin}
        onValid={setPrimaryPass}
        testIdPrefix={TEST_ID.PRIMARY_LOGIN}
      />
      <LoginValidation
        labelPrefix="Alternate"
        login={secondLogin}
        onValid={setSecondPass}
        testIdPrefix={TEST_ID.SECONDARY_LOGIN}
      />

      {primaryPass && secondPass && (
        <ChooseAccounts 
          primaryLogin={primaryLogin} 
          secondLogin={secondLogin} 
          mergeState={mergeState} 
          setLogin={setChosenLogin} />
      )}

      {primaryPass && secondPass && chosenLogin && (
        <MergeAccounts 
          primaryLogin={primaryLogin} 
          secondLogin={secondLogin} 
          primaryPass={primaryPass} 
          secondPass={secondPass} 
          chosenLogin={chosenLogin} 
          mergeState={mergeState} 
          mergeLogin={_apiMergeLogins} 
          setMergeState={setMergeState} />
      )}
    </Modal>
  );
};
