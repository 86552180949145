import React from 'react';
import { Heading, TypographyVisualStyle } from 'react-magma-dom';
import styled from '@emotion/styled';

const StyledHeading = styled(Heading)`
  text-align: center;
  padding: 10px 0px 15px;
  color: #292F7C;
`;

export interface FormHeaderProps {}

export const FormHeader: React.FunctionComponent<FormHeaderProps> = (props) => {
  return (
    <StyledHeading level={1} visualStyle={TypographyVisualStyle.headingSmall} noMargins>
      {props.children}
    </StyledHeading>
  );
};
