import React, { useState } from 'react';
import { Toast, AlertVariant } from 'react-magma-dom';
import { Button, ButtonProps } from '../magma-wrap/Button';

interface ToastProps {
  children: React.ReactNode;
  variant?: AlertVariant;
}

export interface AsyncButtonProps extends ButtonProps {
  onAction: () => Promise<any>;
  onActionChildren?: string | React.ReactNode;

  toastOnSuccess?: React.ReactNode;
  toastOnFailure?: React.ReactNode;

  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
}

export const ActionButton: React.FC<AsyncButtonProps> = props => {
  const {
    toastOnSuccess,
    toastOnFailure,
    onAction,
    onActionChildren,
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const [inAction, setInAction] = useState(false);
  const [toastProps, setToastProps] = useState(
    undefined as ToastProps | undefined
  );
  return (
    <>
      <Button
        {...rest}
        onClick={async (...args) => {
          try {
            setInAction(true);
            await onAction();
            if (onSuccess) {
              onSuccess();
            }
            if (toastOnSuccess) {
              setToastProps({
                children: toastOnSuccess,
                variant: AlertVariant.success
              });
            }
          } catch (error: any) {
            if (onFailure) {
              onFailure(error);
            }
            if (toastOnFailure) {
              setToastProps({
                children: toastOnFailure,
                variant: AlertVariant.danger
              });
            }
          } finally {
            setInAction(false);
          }
          if (props.onClick) {
            props.onClick(...args);
          }
        }}
        disabled={inAction}
      >
        {inAction && onActionChildren ? onActionChildren : props.children}
      </Button>
      {toastProps && (
        <Toast
          onDismiss={() => setToastProps(undefined)}
          variant={toastProps.variant}
        >
          {toastProps.children}
        </Toast>
      )}
    </>
  );
};
