import React from 'react';
import {
  StudentSchoolSearch,
  StudentSchoolSearchProps
} from '../steps/StudentSchoolSearch';
import { BoundaryProps, genPathTo, cleanProps } from './Boundary';

export const path = '/school-connect';

export interface StudentSchoolSearchBoundaryProps
  extends BoundaryProps,
    StudentSchoolSearchProps {}

export const StudentSchoolSearchBoundary: React.FC<StudentSchoolSearchBoundaryProps> = props => {
  props = cleanProps(props);

  return <StudentSchoolSearch {...props} />;
};

export interface SupportedPathOptions {
  // Currently no options (upComm is non-url)
}

export const pathTo = genPathTo<SupportedPathOptions>(path);

StudentSchoolSearchBoundary.defaultProps = {
  path
};
