import {
  EventSender,
  EventSenderOptions,
  EventAPIClient,
  EventDataHandler
} from './shared';
import { ACTIONS, Category } from './tracking-shared';
import {
  CafeEventing,
  DefaultEventingConfiguration
} from '@cafe/cafe-eventing';
import {
  eventPreprocessors,
  defaultPreProcessors
} from './event-preprocessors';

/**
 * For data validation specs:
 * https://wiki.cengage.com/pages/viewpage.action?spaceKey=cap&title=Platform+Analytics+Client+Eventing
 * including valid environment/platform values.
 */
const genDefaultEventClient = () => {
  const windowAny = window as any;
  return new CafeEventing(
    new DefaultEventingConfiguration({
      productEnvironment: windowAny.backend.trackingEnv,
      productPlatform: windowAny.backend.trackingAppName,
      apiKey: windowAny.backend.trackingApiKey,
      eventingEndpoint: windowAny.backend.trackingApiUrl,

      maxBufferSize: 1,
      installOnUnloadHandler: true
    })
  );
};

const genEventSender = (options: EventSenderOptions = {}) => {
  const {
    createEventClient = genDefaultEventClient,
    defPreProcessors = [...defaultPreProcessors]
  } = options;
  const result: any = {};

  // We want to lazy-load the event client because we might want to swap
  // the client out for testing purposes.
  let eventAPIClient: null | EventAPIClient = null;
  const getOrUseEventClient = (): EventAPIClient => {
    if (null === eventAPIClient) {
      eventAPIClient = createEventClient() as EventAPIClient;
    }
    // We assume that we haven't been given a bad EventClient
    return eventAPIClient as EventAPIClient;
  };

  for (const category of Object.keys(ACTIONS) as Category[]) {
    const categoryMap = ACTIONS[category];
    const categoryOut = (result[category] = {} as any);
    // This is ugly, but will have to do for now
    type Action = keyof typeof categoryMap;
    for (const action of Object.keys(categoryMap) as Action[]) {
      // Processors are pre-computed and stay the same
      const processors: EventDataHandler[] = [];
      processors.push(...defPreProcessors);
      processors.push(
        ...((eventPreprocessors?.[category] as any)?.[action] ?? [])
      );

      // The actual event handler
      categoryOut[action] = (data?: any) => {
        try {
          const { category: eventCategory, action: eventAction } = ACTIONS[
            category
          ][action];
          // Pre-process the data
          for (const processor of processors) {
            data = processor(data);
          }

          getOrUseEventClient().recordActivity({
            // Data is loaded first so there is no chance of accidental
            // replacement of category / action
            ...data,

            // These should always go last
            eventCategory,
            eventAction
          });
        } catch (error) {
          // This needs to be safe to call no matter what.
          console?.error(error);
        }
      };
    }
  }

  return result as EventSender;
};

export const trackingClient = {
  event: genEventSender()
};

/**
 * __replaceEventClient is expected to be used strictly with testing
 * and should not be use elsewhere.
 *
 * @param eventClient The event client to use for sending events.
 */
export const __replaceEventClient = (eventClient: any) => {
  trackingClient.event = genEventSender({
    createEventClient: () => eventClient
  });
};
