export enum FlowIds {
  addPassword = 'add-password',
  login = 'login',
  resetPassword = 'reset-password'
}

/**
 * Flow provides a means to have a stronger understanding of what
 * is being attempted since multiple flows are supported for a
 * single controller with the widget. All flows should be
 * checked after checking a controller. Checking a flow by itself
 * is unlikely to be accurate.
 */
export const Flow = {
  isFlow: (flowId: string) => {
    return flowId === window.backend.flowId;
  },
  isAddPassword: () => Flow.isFlow(FlowIds.addPassword),
  isResetPassword: () => Flow.isFlow(FlowIds.resetPassword)
};
